.search-result {
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    padding: .5em;
    &:hover {
        background-color: #ccc;
    }

    &__img {
        flex-basis: 40px;
        flex-grow: 0;
        & > img {
            width: 100%;
            object-fit: contain;
        }
    }

    &__info {
        flex-grow: 1;
        padding-left: 1em;
    }

    &__container {

        &.active {
            animation: fade-in;
            animation-duration: 300ms;
            animation-timing-function: $easing-out-quint;
            animation-fill-mode: forwards;
        }

        &.closing {
            animation: fade-out;
            animation-duration: 300ms;
            animation-timing-function: $easing-out-quint;
            animation-fill-mode: forwards;
        }

        &.closed {
            display: none;
        }

        padding: .5em;
        margin-top: 1em;
        background-color: white;
        border-radius: 5px;
        z-index: 200;
        filter: drop-shadow(-1px 5px 9px rgba(0, 0, 0, 0.4));
    }
}


@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}
