@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
/******************* General ********************/
/******************* Palette ********************/
.font-black {
  color: #222325;
}

.font-grey {
  color: #545A6A;
}

.theme-lime .theme-background {
  background-color: #D5F75C;
}
.theme-lime .theme-background-dark {
  background-color: #1F1F1F;
}
.theme-lime .theme-color {
  color: #1F1F1F;
}
.theme-lime .theme-color-light {
  color: #4F4F4F;
}
.theme-lime .theme-color-dark {
  color: #FCFCFC;
}

.theme-grey .theme-background {
  background-color: #4F4F4F;
}
.theme-grey .theme-background-dark {
  background-color: #D5F75C;
}
.theme-grey .theme-color {
  color: #FCFCFC;
}
.theme-grey .theme-color-dark {
  color: #1F1F1F;
}

.theme-black .theme-background {
  background-color: #1F1F1F;
}
.theme-black .theme-background-dark {
  background-color: #D5F75C;
}
.theme-black .theme-color {
  color: #FCFCFC;
}
.theme-black .theme-color-dark {
  color: #1F1F1F;
}

.theme-purple .theme-background {
  background-color: #F1EDFF;
}
.theme-purple .theme-background-dark {
  background-color: #795AF4;
}
.theme-purple .theme-color {
  color: #795AF4;
}
.theme-purple .theme-color-dark {
  color: #F1EDFF;
}

.theme-water .theme-background {
  background-color: #EBF6FA;
}
.theme-water .theme-background-dark {
  background-color: #19998E;
}
.theme-water .theme-color {
  color: #19998E;
}
.theme-water .theme-color-dark {
  color: #EBF6FA;
}

.theme-brick .theme-background {
  background-color: #FFF8EE;
}
.theme-brick .theme-background-dark {
  background-color: #E45D50;
}
.theme-brick .theme-color {
  color: #E45D50;
}
.theme-brick .theme-color-dark {
  color: #FFF8EE;
}

.theme-green .theme-background {
  background-color: #DCFFE6;
}
.theme-green .theme-background-dark {
  background-color: #18DA48;
}
.theme-green .theme-color {
  color: #18DA48;
}
.theme-green .theme-color-dark {
  color: #DCFFE6;
}

.theme-blue .theme-background {
  background-color: #F5F5F7;
}
.theme-blue .theme-background-dark {
  background-color: #0095D0;
}
.theme-blue .theme-color {
  color: #0095D0;
}
.theme-blue .theme-color-dark {
  color: #F5F5F7;
}

.theme-red .theme-background {
  background-color: #FFF4F4;
}
.theme-red .theme-background-dark {
  background-color: #FA2D48;
}
.theme-red .theme-color {
  color: #FA2D48;
}
.theme-red .theme-color-dark {
  color: #FFF4F4;
}

.theme-orange .theme-background {
  background-color: #FFFBF2;
}
.theme-orange .theme-background-dark {
  background-color: #D96826;
}
.theme-orange .theme-color {
  color: #D96826;
}
.theme-orange .theme-color-dark {
  color: #FFFBF2;
}

/************** Screen Breakpoints **************/
/* ------------------------ General Layout ------------------------ */
html, body {
  font-family: "Sora", sans-serif;
  font-size: 14px;
  position: relative;
  color: #4F4F4F;
  background-color: #FCFCFC;
}

body {
  overflow-x: hidden;
}

strong {
  font-weight: 700;
}

a {
  color: #4F4F4F;
  display: inline-block;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  padding: 0 1px;
}
a:hover {
  color: #1F1F1F;
  text-decoration: underline;
  outline: 0;
  transition: all 0.25s ease-in-out;
}
a:focus {
  text-decoration: underline;
  outline: 0;
}
a.no-underline:hover, a.no-underline:focus {
  text-decoration: none;
}

p {
  font-size: 1em;
  line-height: 1.4;
  margin-bottom: 2em;
}

h1, h2, h3, h4, h5, h6 {
  color: #1F1F1F;
  font-weight: 600;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

h1 {
  font-size: 4em;
  line-height: 1.05;
}

h2 {
  font-size: 3em;
  line-height: 1.075;
}

h3 {
  font-size: 2em;
  line-height: 1.5;
}

h4 {
  font-size: 1.5em;
  line-height: 1.4;
}

h5 {
  font-size: 1.25em;
  line-height: 1.2;
}

h6 {
  font-size: 1em;
  line-height: 1.2;
}

figure {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.background-round, .alert {
  border-radius: 8px;
  padding: 2em;
}
.background-round.background-round-no-padding, .background-round-no-padding.alert {
  padding: 0.2em 0.6em;
}

.round-card {
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 1em;
  padding: 1.5em;
}

.x-btn {
  font-family: "G Display", sans-serif;
  padding: 0.8em 1em;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #1F1F1F;
  border-radius: 16px !important;
  position: relative;
  transition: 0.1s ease-in-out;
}

.x-btn-lg {
  width: 45%;
}
@media screen and (max-width: 575px) {
  .x-btn-lg {
    width: auto;
  }
}

.x-btn-primary {
  color: #1F1F1F !important;
  background-color: #D5F75C !important;
}
.x-btn-primary svg {
  fill: #1F1F1F;
}
.x-btn-primary svg path {
  fill: #1F1F1F;
}
.x-btn-primary:hover {
  color: #FCFCFC !important;
  background-color: #1F1F1F !important;
}
.x-btn-primary:hover svg {
  fill: #FCFCFC;
}
.x-btn-primary:hover svg path {
  fill: #FCFCFC;
}

.x-btn-secondary {
  color: #FCFCFC !important;
  background-color: #1F1F1F !important;
}
.x-btn-secondary svg {
  fill: #FCFCFC;
}
.x-btn-secondary svg path {
  fill: #FCFCFC;
}
.x-btn-secondary:hover {
  color: #1F1F1F !important;
  background-color: #D5F75C !important;
}
.x-btn-secondary:hover svg {
  fill: #1F1F1F;
}
.x-btn-secondary:hover svg path {
  fill: #1F1F1F;
}

.x-font-primary {
  font-family: "Sora", sans-serif;
}

.x-font-secondary {
  font-family: "G Display", sans-serif;
}

.alert {
  border-radius: 0.4em;
  background-color: #fbc8c8;
  color: #cf122b;
  padding: 0.4em 1em;
}
.alert ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.alert.alert-ok {
  background-color: #DCFFE6;
  color: #139b35;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
}
@media screen and (min-width: 992px) {
  body {
    font-size: calc(7px + 6 * (100vw - 476px) / 600);
  }
}
@media screen and (min-width: 1366px) {
  body {
    font-size: 16px;
  }
}
@media screen and (min-width: 2560px) {
  body {
    font-size: 18px;
  }
}
.btn:focus {
  box-shadow: unset;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .img-filter:after {
    content: "";
    background: rgba(71, 173, 156, 0.51);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}
/* -------------------------- Slider ------------------------------------ */
.swiper-button-next::after, .swiper-button-prev::after {
  content: "";
  width: 1em;
  height: 0.5em;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.15s ease-in-out;
}
.swiper-button-next:hover::after, .swiper-button-prev:hover::after {
  opacity: 0.4;
}

.swiper-button-next::after, .swiper-rtl .swiper-button-prev::after {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.233675 0.635177C0.383485 0.484988 0.586812 0.400447 0.798943 0.400147C1.01107 0.399847 1.21464 0.483812 1.36487 0.633577L10.1393 9.37758C10.2213 9.45933 10.2863 9.55646 10.3307 9.6634C10.3751 9.77034 10.398 9.88499 10.398 10.0008C10.398 10.1166 10.3751 10.2312 10.3307 10.3382C10.2863 10.4451 10.2213 10.5422 10.1393 10.624L1.36487 19.368C1.21379 19.5135 1.01159 19.5939 0.801826 19.5918C0.592064 19.5896 0.391524 19.5052 0.243401 19.3567C0.0952792 19.2081 0.0114276 19.0074 0.00990757 18.7976C0.00838751 18.5878 0.0893206 18.3859 0.235275 18.2352L8.49928 9.99998L0.235275 1.76638C0.0850853 1.61657 0.000544986 1.41324 0.000244942 1.20111C-5.51024e-05 0.988977 0.0839098 0.785411 0.233675 0.635177Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center left;
}

.swiper-button-prev::after, .swiper-rtl .swiper-button-next::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7664 19.3648C10.6166 19.515 10.4133 19.5995 10.2011 19.5998C9.98901 19.6001 9.78545 19.5162 9.63522 19.3664L0.860815 10.6224C0.778576 10.5406 0.713312 10.4434 0.668778 10.3363C0.624244 10.2292 0.601318 10.1144 0.601318 9.99842C0.601318 9.88244 0.624244 9.7676 0.668778 9.66052C0.713312 9.55343 0.778576 9.4562 0.860815 9.37442L9.63522 0.632019C9.7863 0.486492 9.9885 0.40613 10.1983 0.408244C10.408 0.410357 10.6086 0.494777 10.7567 0.643318C10.9048 0.79186 10.9887 0.992639 10.9902 1.20241C10.9917 1.41217 10.9108 1.61415 10.7648 1.76482L2.50402 10L10.7664 18.2352C10.9166 18.385 11.0011 18.5884 11.0014 18.8005C11.0017 19.0126 10.9178 19.2162 10.768 19.3664L10.7664 19.3648Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center right;
}

.swiper-pagination-bullet {
  width: 5em;
  height: 0.2em;
  border-radius: 0.5em;
  background-color: #fff;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #D5F75C;
}

.swiper-scrollbar {
  background: none;
}

.swiper-slide img {
  width: 100%;
}

/* -------------------------- Header ------------------------------------ */
#header {
  height: 90px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
#header .header-main-menu {
  height: 100%;
  padding: 0;
}
#header .main-nav {
  height: 100%;
}
#header .main-nav > li {
  height: 100%;
  display: flex;
  align-items: center;
}
#header .navbar-link {
  margin: 0 0.3em;
  padding: 0.3em 0.6em;
}
#header .navbar-link:after {
  content: none;
}
#header .navbar-link:hover {
  text-decoration: none;
  border-radius: 8px;
  outline: 0;
  background-color: #D5F75C;
  transition: all 0.25s ease-in-out;
}
#header .dropdown-menu {
  width: 100%;
  padding: 2em 0;
  margin: 0;
  border: none;
  border-top: 1px solid #000;
  border-radius: 0;
  top: 100%;
  left: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
#header .dropdown-menu .dropdown-column:not(:first-child) {
  border-left: 1px solid #C6C6C6;
}
#header .dropdown-menu .dropdown-column-title {
  font-weight: bold;
}
#header .dropdown-menu .dropdown-list-item {
  display: flex;
}
#header .dropdown-menu .dropdown-list-item > * {
  padding: 0.3em 0.8em;
  margin-bottom: 0.5em;
}
#header .dropdown-menu .dropdown-list-item:last-child {
  text-decoration: underline;
}
@media (max-width: 991px) {
  #header .header-cart-icons > *:not(:last-child) {
    margin-right: 1.2em;
  }
}
#header .header-cart-icons .header-cart-icons-divisor {
  width: 1px;
  height: 20px;
  background-color: #4F4F4F;
}
#header .header-cart-icons .cart-counter {
  position: absolute;
  font-size: 10px;
  font-weight: 700;
  background: #D5F75C;
  padding: 4px;
  width: 2em;
  height: 2em;
  text-align: center;
  border-radius: 50%;
  line-height: 1.2em;
  bottom: -1em;
  right: -0.5em;
}
@media (max-width: 767px) {
  #header .secure-payment svg {
    padding: 2px;
    box-sizing: content-box;
    border-radius: 6px;
    background-color: #D5F75C;
  }
}
#header .mobile-menu-button, #header .desktop-menu-button {
  cursor: pointer;
}
#header .mobile-menu-button svg, #header .desktop-menu-button svg {
  transition: all 0.25s ease-in-out;
}
#header #mobile-menu {
  display: none;
  position: fixed;
  top: 90px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
}
#header #mobile-menu .mobile-menu-accordion {
  padding: 0 1em;
}
#header #mobile-menu .mobile-menu-accordion .mobile-nav-link {
  width: 100%;
  padding: 0.7rem 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 8px;
  overflow-anchor: none;
  box-shadow: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
#header #mobile-menu .mobile-menu-accordion .mobile-nav-link:focus {
  font-weight: 600;
  background-color: #D5F75C;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item {
  border: 0;
  border-bottom: 0;
  border-radius: 0;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item.border-bottom {
  border-bottom: 1px solid black !important;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item .accordion-header {
  margin-bottom: 0;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.7rem 1rem;
  font-size: 1.6rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 8px;
  overflow-anchor: none;
  box-shadow: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item .accordion-button::after {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 2rem;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item .accordion-button:not(.collapsed) {
  font-weight: 600;
  background-color: #D5F75C;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item .accordion-button.menu-mobile-level-1 {
  font-weight: 600;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item .accordion-body {
  padding: 0;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item .accordion-body.menu-mobile-level-1 .accordion-button {
  font-size: 1.4rem;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item .accordion-body.menu-mobile-level-2 {
  font-size: 1.2rem;
}
#header #mobile-menu .mobile-menu-accordion.accordion .accordion-item .accordion-body.menu-mobile-level-2 a {
  display: block;
  padding: 0.5rem 1.25rem;
}

body.mobile-menu-open {
  overflow: hidden;
}
body.mobile-menu-open #header .mobile-menu-button svg {
  transform: rotate(90deg);
}
body.mobile-menu-open #header #mobile-menu {
  display: block;
}
body.desktop-menu-open a {
  cursor: pointer;
}
body.desktop-menu-open #header .desktop-menu-button svg {
  transform: rotate(90deg);
}
body.desktop-menu-open #header #desktop-menu {
  display: block;
}
body.desktop-menu-open .desktop-menu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
  z-index: 50;
}

/* -------------------------- Breadcrumb ------------------------------------ */
.breadcrumb {
  padding: 1.5em 0;
}
.breadcrumb a {
  color: #828282;
}
.breadcrumb span:last-child {
  font-weight: 600;
  color: #1F1F1F;
}
.breadcrumb .breadcrumb-separator {
  margin: 0 0.5em;
}

/* -------------------------- Footer ------------------------------------ */
#footer .newsletter-bg {
  padding: 6em 0;
  background-image: url("/images/app/newsletter-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
@media (max-width: 991px) {
  #footer .newsletter-bg {
    padding: 5em 0;
    background-size: 600px;
    background-position: right bottom;
  }
}
@media (max-width: 767px) {
  #footer .newsletter-bg {
    padding: 2em 0 20em;
    background-image: url("/images/app/newsletter-bg-mobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
@media (max-width: 575px) {
  #footer .newsletter-bg {
    padding: 2em 0 15em;
  }
}
#footer .newsletter-form .newsletter-email-input,
#footer .newsletter-form .btn {
  padding: 0.7em 1.3em;
}
#footer .newsletter-form .newsletter-email-input {
  width: 50%;
  line-height: 1.5;
  border: 1px solid #1F1F1F;
  border-radius: 12px 0 0 12px;
  background-color: #fff;
}
@media (max-width: 575px) {
  #footer .newsletter-form .newsletter-email-input {
    width: 60%;
  }
}
#footer .newsletter-form .btn {
  font-family: "G Display", sans-serif;
  font-size: 0.7em;
  display: flex;
  align-items: center;
  border-radius: 0 12px 12px 0;
}
#footer .newsletter-form .result-message {
  display: none;
  font-weight: bold;
  font-size: 0.9em;
}
#footer .footer-links {
  padding: 2em 0;
  font-size: 1em;
}
#footer .footer-links a {
  color: #545A6A;
  margin-bottom: 1em;
  display: table;
}
#footer .footer-links a:last-child {
  margin-bottom: 0;
}
#footer .footer-links a svg {
  margin-right: 0.4em;
}
#footer .footer-bottom {
  padding: 1.4em 0;
  border-top: 1px solid #BDBDBD;
}
#footer .footer-social a, #footer .footer-social span {
  margin: 0 0.2em;
}
#footer.footer-checkout {
  background-color: #f8f8f8;
  padding: 1.4em 0;
}
#footer.footer-checkout .footer-copy {
  margin: 0;
}

/* purgecss start ignore */
/* -------------------------- Pages ------------------------------------- */
#page-profile .dashboard-sidebar {
  list-style: none;
  padding: 0;
}
#page-profile .dashboard-sidebar li {
  padding: 1em 0;
  border-bottom: 1px solid #C6C6C6;
  margin-right: 1em;
}
#page-profile .dashboard-sidebar li:first-child {
  border-top: 1px solid #C6C6C6;
}
#page-profile .dashboard-sidebar li a:hover, #page-profile .dashboard-sidebar li a.active {
  font-weight: bold;
}
#page-profile .checkout-form-title {
  font-size: 1.4em;
  font-weight: 600;
}
#page-profile .invoice-field-title {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.1s;
}
#page-profile .invoice-field-title.disabled {
  font-size: 1em;
  font-weight: 400;
  color: #C6C6C6;
  text-decoration: none;
  transition: 0.3s;
}
#page-profile .form-group label.required:after {
  content: "*";
}
#page-profile .orders .order-item:last-child .divisor {
  display: none;
}
#page-profile .orders .product-image img {
  width: 100%;
  border-radius: 1em;
}
#page-profile .orders .product-description p {
  margin-bottom: 0;
}
#page-profile .orders .product-category {
  font-family: "G Display Extended", sans-serif;
  font-size: 0.7em;
  display: inline-block;
  padding: 0.2em 0.6em;
  text-transform: uppercase;
  border-radius: 6px;
  color: #1F1F1F;
  background-color: #D5F75C;
}
@media (min-width: 992px) {
  #page-profile .orders .download-btn .x-btn {
    border-radius: 50% !important;
  }
}
#page-profile .shipping-address span, #page-profile .invoice-address span, #page-profile .payments-method span {
  font-size: 0.8em;
}

#page-catalog .catalog-header select {
  background-color: #fff;
  border: 1px solid #e3e5e9;
}
@media (max-width: 767px) {
  #page-catalog .catalog-header select {
    width: 200px;
  }
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item {
  border: none;
  border-bottom: 1px solid #C6C6C6;
  background-color: transparent;
}
@media (max-width: 991px) {
  #page-catalog .catalog-container .filters-container .accordion .accordion-item {
    border-color: #4F4F4F;
  }
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .accordion-button {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
  color: #1F1F1F;
  background-color: transparent;
  box-shadow: none;
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .accordion-button::after {
  width: 0.85em;
  height: 0.85em;
  background-size: 0.85em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
@media (max-width: 991px) {
  #page-catalog .catalog-container .filters-container .accordion .accordion-item .accordion-button {
    color: #fff;
  }
  #page-catalog .catalog-container .filters-container .accordion .accordion-item .accordion-button::after {
    background-image: url("/images/app/demo/arrow-down-white.svg");
  }
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .accordion-body {
  padding: 0;
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox {
  line-height: 1.1;
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox label {
  font-size: 0.8em;
  margin: 0;
  padding-left: 2.5em;
  position: relative;
  cursor: pointer;
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border: 1px solid #4F4F4F;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
}
@media (max-width: 991px) {
  #page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox label::before {
    border-color: #fff;
  }
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox label:hover {
  color: #1F1F1F;
  font-weight: 600;
}
@media (max-width: 991px) {
  #page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox label:hover {
    color: #D5F75C;
  }
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox input[type=checkbox] {
  display: none;
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox input[type=checkbox]:checked + label {
  color: #1F1F1F;
  font-weight: 600;
}
@media (max-width: 991px) {
  #page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox input[type=checkbox]:checked + label {
    color: #D5F75C;
  }
}
#page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox input[type=checkbox]:checked + label::before {
  border-color: #1F1F1F;
  background-color: #D5F75C;
  box-shadow: inset 0 0 1px 1px #fff;
}
@media (max-width: 991px) {
  #page-catalog .catalog-container .filters-container .accordion .accordion-item .checkbox input[type=checkbox]:checked + label::before {
    border-color: #fff;
    box-shadow: inset 0 0 1px 1px #1F1F1F;
  }
}
#page-catalog .catalog-container .catalog .product {
  position: relative;
  display: flex;
  min-height: 390px;
  font-size: 1em;
  border-radius: 1em;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
}
#page-catalog .catalog-container .catalog .product a {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#page-catalog .catalog-container .catalog .product .tag-container {
  z-index: 2;
  position: absolute;
  top: 1em;
  left: 1em;
}
#page-catalog .catalog-container .catalog .product .tag-container .tag-content .tag {
  font-size: 0.8em;
  padding: 0.25em 0.5em;
  text-transform: uppercase;
  line-height: initial;
  color: #1F1F1F;
  border: 1px solid #1F1F1F;
  border-radius: 0.5em;
  background-color: #FCFCFC;
}
#page-catalog .catalog-container .catalog .product .image-container figure {
  overflow: hidden;
  border-radius: 1em;
}
#page-catalog .catalog-container .catalog .product .image-container img {
  width: 100%;
  border-radius: 1em;
  transition: 0.3s;
}
#page-catalog .catalog-container .catalog .product .product-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1em;
}
#page-catalog .catalog-container .catalog .product .category-container {
  font-family: "G Display", sans-serif;
  font-size: 0.7em;
  text-transform: uppercase;
}
#page-catalog .catalog-container .catalog .product .name-container {
  display: flex;
  flex-grow: 1;
}
#page-catalog .catalog-container .catalog .product .subcategories-container .subcategories-content {
  font-family: "G Display", sans-serif;
  font-size: 0.7em;
}
#page-catalog .catalog-container .catalog .product .subcategories-container .subcategory-pick {
  display: inline-block;
  border-radius: 6px;
  padding: 0.2em 0.6em;
  text-transform: uppercase;
  background-color: #D5F75C;
}
#page-catalog .catalog-container .catalog .product hr {
  border: 1px solid #C6C6C6;
}
#page-catalog .catalog-container .catalog .product .price-container {
  font-size: 0.8em;
}
#page-catalog .catalog-container .catalog .product .price-container .price-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#page-catalog .catalog-container .catalog .product .price-container .price-content .final-price {
  font-size: 1.4em;
  margin-right: 5px;
}
#page-catalog .catalog-container .catalog .product .price-container .price-content .original-price {
  color: #C6C6C6;
  text-decoration: line-through;
}
#page-catalog .catalog-container .catalog .product:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08);
  transition: 0.3s;
}
#page-catalog .catalog-container .catalog .product:hover .image-container {
  overflow: hidden;
}
#page-catalog .catalog-container .catalog .product:hover .image-container img {
  transform: scale(1.05);
  transition: 0.3s;
}
#page-catalog .mobile-filter-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #1F1F1F;
  border-radius: 8px;
  padding: 0.5em;
  font-size: 1.1em;
  transition: 0.1s ease-in-out;
}
#page-catalog .mobile-filter-button.open-filters {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
#page-catalog .mobile-filter-button.open-filters:hover {
  background-color: #D5F75C;
}
#page-catalog .mobile-filter-button svg {
  margin-right: 0.7em;
}

#page-product .mobile-fixed-price {
  z-index: 99;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.08);
}
#page-product .mobile-fixed-price .final-price {
  color: #1F1F1F;
  font-size: 1.8em;
  font-weight: bold;
  margin-right: 5px;
}
#page-product .mobile-fixed-price .original-price {
  color: #C6C6C6;
  text-decoration: line-through;
  font-size: 0.9em;
}
#page-product .product-container {
  margin-bottom: 5em;
}
#page-product .product-container .product-title-container {
  padding-bottom: 1em;
  border-bottom: 1px solid #C6C6C6;
}
#page-product .product-container .product-title-container h1 {
  font-size: 2em;
}
#page-product .product-container .product-image-container img {
  width: 100%;
  border-radius: 24px;
}
#page-product .product-container .product-video iframe {
  width: 100%;
}
#page-product .product-container .product-video .video-caption {
  font-size: 0.8em;
}
#page-product .product-container .product-video h4 {
  margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
  #page-product .product-container .product-video ul {
    columns: 2;
  }
}
#page-product .product-container .product-video ul li {
  margin-bottom: 1rem;
}
#page-product .product-container .product-video ul li:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 1em;
  background: #D5F75C;
  border: 1px solid #1F1F1F;
  border-radius: 50%;
}
#page-product .product-container .product-video ul, #page-product .product-container .product-video ol {
  margin-bottom: 3rem;
}
#page-product .product-container .product-tags .product-tags-list {
  display: flex;
}
#page-product .product-container .product-tags .product-tags-list .product-tag {
  display: inline-block;
  font-size: 0.7em;
  padding: 0.2em 0.6em;
  margin-right: 0.5em;
  text-transform: uppercase;
  border-radius: 6px;
  color: #FCFCFC;
  background-color: #4F4F4F;
}
#page-product .product-container .product-selections-container {
  position: sticky;
  top: 10em;
}
@media (max-width: 767px) {
  #page-product .product-container .product-selections-container {
    position: static;
  }
}
#page-product .product-container .product-selections-container .selection-summary .final-price {
  color: #1F1F1F;
  font-size: 1.8em;
  font-weight: bold;
  margin-right: 5px;
}
#page-product .product-container .product-selections-container .selection-summary .original-price {
  color: #C6C6C6;
  text-decoration: line-through;
  font-size: 0.9em;
}
#page-product .product-container .product-selections-container .product-features .product-feature-container {
  position: relative;
  padding-left: 1.3em;
  border-left: 2px solid #1F1F1F;
}
#page-product .product-container .product-selections-container .product-features .product-feature-container:last-child {
  border-color: transparent;
}
#page-product .product-container .product-selections-container .product-features .product-feature-container:not(:last-child) {
  padding-bottom: 1.2em;
}
#page-product .product-container .product-selections-container .product-features .product-feature-point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1F1F1F;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-60%);
}
#page-product .product-container .product-selections-container .product-features .product-category-container {
  transform: translateY(-4px);
}
#page-product .product-container .product-selections-container .product-features .product-feature-title {
  font-size: 0.8em;
  color: #1F1F1F;
}
#page-product .product-container .product-selections-container .product-features .product-category {
  font-family: "G Display Extended", sans-serif;
  font-size: 0.7em;
  display: inline-block;
  padding: 0.2em 0.6em;
  text-transform: uppercase;
  border-radius: 6px;
  color: #1F1F1F;
  background-color: #D5F75C;
}
#page-product .product-container .product-selections-container .product-terms .product-terms-list {
  font-size: 0.9em;
}
#page-product .product-container .product-selections-container .product-terms .product-terms-list .list-point {
  display: inline-block;
  margin-right: 0.7em;
}
#page-product .product-container .product-selections-container .notes-description {
  font-size: 0.8em;
  margin: 0.6em 0;
}
#page-product .product-container .product-selections-container .notes-description .note {
  padding-left: 0.6em;
  line-height: 1.2;
  color: #4F4F4F;
  border-left: 2px solid #1F1F1F;
}
#page-product .product-container .product-selections-container .notes-description .note p {
  display: inline;
}
#page-product .product-container .product-selections-container .cart-button-container .cart-button {
  display: block;
}
#page-product .product-container .product-selections-container .cart-button-container .cart-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

#page-checkout {
  padding-bottom: 5em;
}
#page-checkout .checkout-steps {
  margin: 2em 0;
}
@media screen and (max-width: 991px) {
  #page-checkout .checkout-steps {
    font-size: 0.8em;
  }
}
#page-checkout .checkout-steps .checkout-step {
  display: flex;
  align-items: center;
  opacity: 0.3;
}
@media screen and (max-width: 575px) {
  #page-checkout .checkout-steps .checkout-step {
    flex-direction: column;
    align-items: center;
  }
}
#page-checkout .checkout-steps .checkout-step .icon-step {
  margin-right: 0.3em;
}
@media screen and (max-width: 575px) {
  #page-checkout .checkout-steps .checkout-step .icon-step {
    margin-right: 0;
  }
}
#page-checkout .checkout-steps .checkout-step.active {
  opacity: 1;
}
#page-checkout .checkout-steps .checkout-step.separator {
  margin: 0 1em;
}
@media screen and (max-width: 575px) {
  #page-checkout .checkout-steps .checkout-step.separator {
    margin: 0 0.5em;
    width: 65px;
  }
}
#page-checkout .checkout-form-container {
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
  border-radius: 1em;
  padding: 1.5em;
}
#page-checkout .checkout-form-container .registration-notice {
  padding: 1em;
  border-radius: 1em;
}
#page-checkout .checkout-form-container .checkout-form-title {
  font-size: 1.4em;
  font-weight: 600;
}
#page-checkout .checkout-form-container .invoice-field-title {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.1s;
}
#page-checkout .checkout-form-container .invoice-field-title.disabled {
  font-size: 1em;
  font-weight: 400;
  color: #C6C6C6;
  text-decoration: none;
  transition: 0.3s;
}
#page-checkout .checkout-form-container .form-group label.required:after {
  content: "*";
}
#page-checkout .checkout-form-container .checkout-form-recap {
  margin-top: 1em;
  margin-bottom: 1em;
}
#page-checkout .checkout-form-container .checkout-form-recap .checkout-form-recap-content {
  font-size: 0.9em;
}
#page-checkout .checkout-form-container .checkout-form-recap .checkout-form-recap-content .credential {
  color: #1F1F1F;
  margin-left: 0.8em;
}
#page-checkout .checkout-form-container .checkout-form-recap.checkout-form-recap-contact {
  padding-right: 2em;
}
#page-checkout .checkout-form-container .checkout-form-recap.checkout-form-recap-invoice {
  border-left: 1px solid #e3e5e9;
  padding-left: 2em;
}
@media screen and (max-width: 991px) {
  #page-checkout .checkout-form-container .checkout-form-recap.checkout-form-recap-contact {
    padding-right: 0;
  }
  #page-checkout .checkout-form-container .checkout-form-recap.checkout-form-recap-invoice {
    border: none;
    padding-left: 0;
  }
}
#page-checkout .checkout-form-container .accept-policy {
  color: #C6C6C6;
  font-size: 0.8em;
  font-style: italic;
}
#page-checkout .checkout-summary {
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 1em;
  padding: 1.5em;
  margin-top: 2em;
  top: 5em;
}
@media (max-width: 991px) {
  #page-checkout .checkout-summary {
    margin-top: 0;
  }
}
#page-checkout .checkout-summary.sticky-lg-top {
  z-index: 1019;
}
#page-checkout .checkout-summary .checkout-summary-close .close-checkout-summary {
  cursor: pointer;
}
#page-checkout .checkout-summary .checkout-summary-title {
  font-size: 1.2em;
  font-weight: 600;
}
#page-checkout .checkout-summary .insert-coupon .coupon-input {
  border-color: #1F1F1F;
}
#page-checkout .checkout-summary .insert-coupon .coupon-btn {
  margin-left: 0.6em;
}
#page-checkout .checkout-summary .checkout-summary-product-list {
  font-size: 0.9em;
}
#page-checkout .checkout-summary .checkout-summary-product-list .image-container {
  width: 6em;
  margin-right: 0.5em;
}
#page-checkout .checkout-summary .checkout-summary-product-list .image-container img {
  width: 100%;
  border-radius: 8px;
}
#page-checkout .checkout-summary .product-category {
  font-family: "G Display Extended", sans-serif;
  font-size: 0.7em;
  display: inline-block;
  padding: 0.2em 0.6em;
  text-transform: uppercase;
  border-radius: 6px;
  color: #1F1F1F;
  background-color: #D5F75C;
}
#page-checkout .checkout-summary .checkout-summary-totals .total {
  color: #1F1F1F;
  font-size: 1.8em;
}
#page-checkout .checkout-summary .checkout-summary-totals .total-save {
  font-size: 0.8em;
}
#page-checkout .checkout-summary .form-control {
  border-radius: 0.5em;
}
#page-checkout .checkout-step2 .payment-method {
  border: 0;
}
#page-checkout .checkout-step2 .payment-method > .form-group label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #C6C6C6;
  border-radius: 0.3em;
}
#page-checkout .checkout-step2 .payment-method input[type=radio]:checked + label {
  border: 1px solid #1F1F1F;
}
#page-checkout .checkout-step2 .form-group.form-radio label {
  font-size: 0.9em;
  margin: 0;
  padding: 1.3em 0.8em 1.3em 3em;
}
#page-checkout .checkout-step2 .form-group.form-radio label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border: 1px solid #4F4F4F;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
}
#page-checkout .checkout-step2 .form-group.form-radio label:hover {
  color: #1F1F1F;
  font-weight: 600;
  border-color: #1F1F1F;
}
#page-checkout .checkout-step2 .form-group.form-radio input[type=radio] {
  display: none;
}
#page-checkout .checkout-step2 .form-group.form-radio input[type=radio]:checked + label {
  color: #1F1F1F;
  font-weight: 600;
}
#page-checkout .checkout-step2 .form-group.form-radio input[type=radio]:checked + label::before {
  border-color: #1F1F1F;
  background-color: #D5F75C;
  box-shadow: inset 0 0 1px 1px #fff;
}

#page-cart {
  padding-bottom: 5em;
}
#page-cart .cart-summary-product-container .item-round-card:not(:last-child),
#page-cart #upsellProductsModal .item-round-card:not(:last-child) {
  margin: 0.6em 0;
}
#page-cart .cart-summary-product-container .item-round-card .round-card,
#page-cart #upsellProductsModal .item-round-card .round-card {
  width: 100%;
}
#page-cart .cart-summary-product-container .product-image img,
#page-cart #upsellProductsModal .product-image img {
  width: 100%;
  border-radius: 1em;
}
#page-cart .cart-summary-product-container .product-category,
#page-cart #upsellProductsModal .product-category {
  font-family: "G Display Extended", sans-serif;
  font-size: 0.7em;
  display: inline-block;
  padding: 0.2em 0.6em;
  text-transform: uppercase;
  border-radius: 6px;
  color: #1F1F1F;
  background-color: #D5F75C;
}
#page-cart .cart-summary-product-container .product-description,
#page-cart #upsellProductsModal .product-description {
  font-size: 0.9em;
}
#page-cart .cart-summary-product-container .product-description p,
#page-cart #upsellProductsModal .product-description p {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  #page-cart .cart-summary-product-container .add-upsell-product-label-container,
#page-cart #upsellProductsModal .add-upsell-product-label-container {
    text-align: right;
  }
}
#page-cart .cart-summary-product-container .remove-product,
#page-cart .cart-summary-product-container .add-upsell-product,
#page-cart #upsellProductsModal .remove-product,
#page-cart #upsellProductsModal .add-upsell-product {
  display: none;
}
#page-cart .cart-summary-product-container .remove-product-label,
#page-cart .cart-summary-product-container .add-upsell-product-label,
#page-cart #upsellProductsModal .remove-product-label,
#page-cart #upsellProductsModal .add-upsell-product-label {
  color: inherit;
  font-size: 0.9em;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
#page-cart .cart-summary-product-container .remove-product-label:hover,
#page-cart .cart-summary-product-container .add-upsell-product-label:hover,
#page-cart #upsellProductsModal .remove-product-label:hover,
#page-cart #upsellProductsModal .add-upsell-product-label:hover {
  text-decoration: none;
}
#page-cart .cart-summary-product-container .remove-product-label .trash-icon,
#page-cart .cart-summary-product-container .add-upsell-product-label .trash-icon,
#page-cart #upsellProductsModal .remove-product-label .trash-icon,
#page-cart #upsellProductsModal .add-upsell-product-label .trash-icon {
  margin-left: 0.3em;
}
@media (min-width: 992px) {
  #page-cart .cart-summary-product-container .product-price,
#page-cart #upsellProductsModal .product-price {
    text-align: right;
  }
}
#page-cart .cart-summary-product-container .final-price,
#page-cart #upsellProductsModal .final-price {
  color: #1F1F1F;
  font-size: 1.5em;
  font-weight: bold;
}
#page-cart .cart-summary-product-container .original-price,
#page-cart #upsellProductsModal .original-price {
  color: #C6C6C6;
  text-decoration: line-through;
  font-size: 0.9em;
}
#page-cart .cart-summary-product-container .tag-product-added,
#page-cart #upsellProductsModal .tag-product-added {
  margin-left: 1em;
  font-size: 0.8em;
  background-color: #EBF6FA;
  color: #19998E;
  border-radius: 2em;
  padding: 0.3em 0.5em;
}
#page-cart .checkout-summary .form-control {
  border-radius: 0.5em;
}
#page-cart .checkout-summary.sticky-lg-top {
  z-index: 1019;
}
#page-cart .insert-coupon .coupon-input {
  border-color: #1F1F1F;
}
#page-cart .insert-coupon .coupon-btn {
  margin-left: 0.6em;
}
#page-cart .product-terms .product-terms-list {
  font-size: 0.9em;
}
#page-cart .product-terms .product-terms-list .list-point {
  display: inline-block;
  margin-right: 0.7em;
}
#page-cart .total {
  color: #1F1F1F;
  font-size: 1.8em;
}
#page-cart .contacts,
#page-cart .infos {
  font-size: 0.9em;
}

.mobile-summary-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px -14px 20px 0px #e3e5e9;
  padding: 1em;
}
.mobile-summary-bar .checkout-summary-totals .total {
  color: #27AE60;
  font-size: 1.2em;
}
.mobile-summary-bar .checkout-summary-totals .open-checkout-summary {
  cursor: pointer;
}

.info-bar {
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 1em;
  padding: 1.5em;
  margin-top: 2em;
  font-size: 0.9em;
}

body.mobile-filter-open, body.mobile-checkout-summary-open {
  overflow: hidden;
}
body.mobile-filter-open #header, body.mobile-checkout-summary-open #header {
  display: none;
}
body.mobile-filter-open .catalog-container .filters-container, body.mobile-checkout-summary-open .catalog-container .filters-container {
  z-index: 99;
  display: block !important;
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  color: #fff;
  background-color: #1F1F1F;
}
body.mobile-filter-open .catalog-container .filters-container .filter-title, body.mobile-checkout-summary-open .catalog-container .filters-container .filter-title {
  color: #fff;
  margin-bottom: 0;
}
body.mobile-filter-open .catalog-container .filters-container .filters-close, body.mobile-checkout-summary-open .catalog-container .filters-container .filters-close {
  border-bottom: 1px solid #4F4F4F;
}
body.mobile-checkout-summary-open .checkout-summary {
  display: block !important;
}

/* --------------------------  Blocchi da creare ---------------------------- */
.page-title {
  font-size: 2em;
}

.dk-block {
  margin-bottom: 5em;
}

.section-padding {
  padding: 2em 0;
}

.dk-block-about {
  position: relative;
}
@media (max-width: 991px) {
  .dk-block-about .theme-background {
    background-color: #1F1F1F;
  }
}
.dk-block-about .dk-block-about-container {
  padding: 3em 0;
}
@media (max-width: 767px) {
  .dk-block-about .dk-block-about-container {
    padding: 4em 0;
  }
}
.dk-block-about .dk-block-about-container .dk-block-about-left-col img {
  margin-right: 1.5em;
}
@media (max-width: 767px) {
  .dk-block-about .dk-block-about-container .dk-block-about-left-col img {
    width: 60px;
  }
}
@media (max-width: 575px) {
  .dk-block-about .dk-block-about-container .dk-block-about-left-col img {
    margin-right: 1em;
  }
}
.dk-block-about .dk-block-about-container .dk-block-about-left-col .description {
  font-size: 2em;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.6em;
}
@media (min-width: 992px) {
  .dk-block-about .dk-block-about-container .dk-block-about-left-col .description {
    margin: 1em 0;
  }
}
.dk-block-about .dk-block-about-container .dk-block-about-right-col {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px 0 0 24px;
  overflow: hidden;
}
.dk-block-about .dk-block-about-container .dk-block-about-right-col .image {
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  background-image: url("/images/app/demo/home_about_us.jpg");
}
.dk-block-about .dk-block-about-container .about-cta {
  width: 100%;
}

.dk-block-see-more .dk-block-see-more-container {
  border-radius: 24px;
}
.dk-block-see-more .theme-black {
  border-radius: 24px;
  background-color: #1F1F1F;
}
@media (max-width: 991px) {
  .dk-block-see-more .black-square {
    height: 300px;
  }
}
@media (max-width: 575px) {
  .dk-block-see-more .black-square {
    height: 250px;
  }
}
.dk-block-see-more .dk-block-see-more-content {
  padding: 3em 4em;
}
@media (max-width: 991px) {
  .dk-block-see-more .dk-block-see-more-content {
    padding: 2em;
  }
}
@media (max-width: 575px) {
  .dk-block-see-more .dk-block-see-more-content {
    padding: 1.5em 1.2em 1.2em;
  }
}
.dk-block-see-more .see-more-cta {
  width: 50%;
}
@media (max-width: 575px) {
  .dk-block-see-more .see-more-cta {
    width: 100%;
  }
}

.dk-block-about-agency .content-block {
  text-align: center;
  color: #1F1F1F;
  border: 1px solid #1F1F1F;
  border-radius: 48px;
  overflow: hidden;
}
.dk-block-about-agency .content-block .number {
  font-size: 2em;
}
.dk-block-about-agency .content-block .caption {
  font-size: 0.8em;
}
.dk-block-about-agency .content-block .image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.dk-block-about-agency .people-content,
.dk-block-about-agency .adv-content {
  background: #D5F75C;
}
.dk-block-about-agency .experiments-content {
  min-height: 300px;
}
.dk-block-about-agency .experiments-content .image {
  background-image: url("/images/app/demo/experiments_launched.jpg");
}
@media (max-width: 767px) {
  .dk-block-about-agency .foundation-content {
    height: 150px;
  }
}
.dk-block-about-agency .foundation-content .image {
  background-image: url("/images/app/demo/2018_foundation.jpg");
}

.dk-block-category .dk-block-category-wrapper {
  height: 100%;
  border-radius: 16px;
  background-color: #F6F6F6;
}
.dk-block-category .dk-block-category-image {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dk-block-category .dk-block-category-title {
  margin: 1em 0;
  font-weight: 600;
}

.dk-block-features .dk-block-features-title {
  margin: 0.5em 0;
  font-weight: 600;
}

.dk-block-top-offers,
.dk-block-recommended {
  overflow: hidden;
}
.dk-block-top-offers .page-title,
.dk-block-recommended .page-title {
  position: relative;
}
@media (max-width: 767px) {
  .dk-block-top-offers .page-title,
.dk-block-recommended .page-title {
    display: flex;
    justify-content: space-between;
  }
}
.dk-block-top-offers .swiper,
.dk-block-recommended .swiper {
  padding-bottom: 10px;
}
.dk-block-top-offers .swiper-slide,
.dk-block-recommended .swiper-slide {
  height: auto;
}
@media (max-width: 767px) {
  .dk-block-top-offers .swiper-buttons-container,
.dk-block-recommended .swiper-buttons-container {
    position: relative;
  }
  .dk-block-top-offers .swiper-buttons-container .swiper-button-prev,
.dk-block-recommended .swiper-buttons-container .swiper-button-prev {
    right: 5px;
    left: auto;
  }
  .dk-block-top-offers .swiper-buttons-container .swiper-button-next,
.dk-block-recommended .swiper-buttons-container .swiper-button-next {
    left: 5px;
    right: auto;
  }
}
@media (max-width: 575px) {
  .dk-block-top-offers .swiper-buttons-container,
.dk-block-recommended .swiper-buttons-container {
    display: none;
  }
}
.dk-block-top-offers .swiper-button-next,
.dk-block-top-offers .swiper-button-prev,
.dk-block-recommended .swiper-button-next,
.dk-block-recommended .swiper-button-prev {
  width: auto;
  height: auto;
}
.dk-block-top-offers .swiper-button-next::after,
.dk-block-top-offers .swiper-button-prev::after,
.dk-block-recommended .swiper-button-next::after,
.dk-block-recommended .swiper-button-prev::after {
  width: 40px;
  height: 40px;
}
.dk-block-top-offers .swiper-button-prev,
.dk-block-recommended .swiper-button-prev {
  left: 0;
}
.dk-block-top-offers .swiper-button-next,
.dk-block-recommended .swiper-button-next {
  right: 0;
}
.dk-block-top-offers .swiper-button-next::after, .dk-block-top-offers .swiper-rtl .swiper-button-prev::after,
.dk-block-recommended .swiper-button-next::after,
.dk-block-recommended .swiper-rtl .swiper-button-prev::after {
  background-image: url("/images/app/demo/arrow-right.svg");
  background-position: center left;
}
.dk-block-top-offers .swiper-button-prev::after, .dk-block-top-offers .swiper-rtl .swiper-button-next::after,
.dk-block-recommended .swiper-button-prev::after,
.dk-block-recommended .swiper-rtl .swiper-button-next::after {
  background-image: url("/images/app/demo/arrow-right.svg");
  background-position: center right;
  transform: rotate(180deg);
}
.dk-block-top-offers .swiper-button-disabled,
.dk-block-recommended .swiper-button-disabled {
  display: none;
}
.dk-block-top-offers .product,
.dk-block-recommended .product {
  position: relative;
  display: flex;
  height: 100%;
  font-size: 1em;
  border-radius: 1em;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
}
.dk-block-top-offers .product a,
.dk-block-recommended .product a {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.dk-block-top-offers .product .tag-container,
.dk-block-recommended .product .tag-container {
  z-index: 2;
  position: absolute;
  top: 1em;
  left: 1em;
}
.dk-block-top-offers .product .tag-container .tag-content .tag,
.dk-block-recommended .product .tag-container .tag-content .tag {
  font-size: 0.8em;
  padding: 0.25em 0.5em;
  text-transform: uppercase;
  line-height: initial;
  color: #1F1F1F;
  border: 1px solid #1F1F1F;
  border-radius: 0.5em;
  background-color: #FCFCFC;
}
.dk-block-top-offers .product .image-container figure,
.dk-block-recommended .product .image-container figure {
  overflow: hidden;
  border-radius: 1em;
}
.dk-block-top-offers .product .image-container img,
.dk-block-recommended .product .image-container img {
  width: 100%;
  border-radius: 1em;
  transition: 0.3s;
}
.dk-block-top-offers .product .product-content,
.dk-block-recommended .product .product-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1em;
}
.dk-block-top-offers .product .category-container,
.dk-block-recommended .product .category-container {
  font-size: 0.7em;
  text-transform: uppercase;
}
.dk-block-top-offers .product .name-container,
.dk-block-recommended .product .name-container {
  display: flex;
  flex-grow: 1;
}
.dk-block-top-offers .product .subcategories-container .subcategories-content,
.dk-block-recommended .product .subcategories-container .subcategories-content {
  font-size: 0.8em;
}
.dk-block-top-offers .product .subcategories-container .subcategory-pick,
.dk-block-recommended .product .subcategories-container .subcategory-pick {
  display: inline-block;
  font-size: 0.9em;
  border-radius: 6px;
  padding: 0.2em 0.6em;
  text-transform: uppercase;
  background-color: #D5F75C;
}
.dk-block-top-offers .product hr,
.dk-block-recommended .product hr {
  border: 1px solid #C6C6C6;
}
.dk-block-top-offers .product .price-container,
.dk-block-recommended .product .price-container {
  font-size: 0.8em;
}
.dk-block-top-offers .product .price-container .price-content,
.dk-block-recommended .product .price-container .price-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dk-block-top-offers .product .price-container .price-content .final-price,
.dk-block-recommended .product .price-container .price-content .final-price {
  font-size: 1.4em;
  margin-right: 5px;
}
.dk-block-top-offers .product .price-container .price-content .original-price,
.dk-block-recommended .product .price-container .price-content .original-price {
  color: #C6C6C6;
  text-decoration: line-through;
}
.dk-block-top-offers .product:hover,
.dk-block-recommended .product:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08);
  transition: 0.3s;
}
.dk-block-top-offers .product:hover .image-container,
.dk-block-recommended .product:hover .image-container {
  overflow: hidden;
}
.dk-block-top-offers .product:hover .image-container img,
.dk-block-recommended .product:hover .image-container img {
  transform: scale(1.05);
  transition: 0.3s;
}

.dk-block-top-offers .page-title {
  padding-right: 2em;
}
.dk-block-top-offers .swiper {
  overflow: visible;
}

@media (max-width: 575px) {
  .dk-block-recommended .swiper {
    overflow: visible;
  }
}

.app-guest main {
  background-color: #f8f8f8;
  min-height: calc(100vh - 11em);
}

.notifications-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3000;
}
.notifications-container .container {
  position: relative;
}
.notifications-container .container .notification-track {
  position: absolute;
  right: 15px;
  top: 100px;
  width: 300px;
  z-index: 3100;
  transition: opacity 0.5s;
  opacity: 1;
}
.notifications-container .container .notification-track.hide {
  opacity: 0;
  display: block !important;
}
.notifications-container .container .notification-track .notification {
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  will-change: opacity;
  transition: opacity 0.3s, max-height 1s;
}
.notifications-container .container .notification-track .notification .notification-content {
  padding: 15px;
  margin: 8px 8px 20px;
  color: #333333;
  background-color: #D5F75C;
  -webkit-box-shadow: 0 0 12px #333;
  box-shadow: 0 0 12px #333;
}
.notifications-container .container .notification-track .notification .notification-content .notification-header {
  width: auto;
  margin: -15px -15px 10px;
  padding: 15px;
  color: #1F1F1F;
  background-color: #fff;
}
.notifications-container .container .notification-track .notification .notification-content .notification-header figure {
  margin-bottom: 10px;
}
.notifications-container .container .notification-track .notification .notification-content .notification-header figure img {
  width: 100%;
}
.notifications-container .container .notification-track .notification.active {
  opacity: 1;
  max-height: none;
  transition: opacity 0.5s;
}
.notifications-container .container .notification-track #notification-template {
  display: none;
}

#spinner.loader {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 999;
  text-align: center;
}
#spinner.loader.hidden {
  display: none;
}
#spinner.loader .loader-wheel {
  animation: spin 1s infinite linear;
  border: 2px solid #4F4F4F;
  border-left: 4px solid #D5F75C;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 0 auto;
}
#spinner.loader .loader-text:after {
  content: "Ricerca";
  animation: load 2s linear infinite;
}
#spinner.loader .loader-text {
  color: #4F4F4F;
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    content: "Ricerca";
  }
  33% {
    content: "Ricerca.";
  }
  67% {
    content: "Ricerca..";
  }
  100% {
    content: "Ricerca...";
  }
}

.auth-container {
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 4em);
  padding: 1em 0;
  background-image: linear-gradient(to bottom, #D5F75C, #FCFCFC);
}
.auth-container .auth-box {
  padding: 1.5em 2em 2em;
  background-color: #FCFCFC;
}
.auth-container .auth-box p {
  margin: 0;
}
.auth-container .auth-box .heading .title {
  display: flex;
  justify-content: space-between;
}
.auth-container .auth-box .heading .title p {
  font-size: 0.9em;
  color: #4F4F4F;
}
.auth-container .auth-box .heading .description {
  margin-bottom: 2em;
  font-size: 0.9em;
}
.auth-container .auth-box .content .forgot-password {
  display: block;
  width: 100%;
  text-align: right;
  color: inherit;
  font-size: 0.9em;
}
.auth-container .auth-box .content .new-user {
  font-size: 0.9em;
}
@media (max-width: 991px) {
  .auth-container .auth-box .heading .title {
    display: block;
    margin-bottom: 1em;
  }
}

/* -------------------------- Profile Page ----------------------------- */
/* purgecss end ignore */
.search-result {
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 0.5em;
}
.search-result:hover {
  background-color: #ccc;
}
.search-result__img {
  flex-basis: 40px;
  flex-grow: 0;
}
.search-result__img > img {
  width: 100%;
  object-fit: contain;
}
.search-result__info {
  flex-grow: 1;
  padding-left: 1em;
}
.search-result__container {
  padding: 0.5em;
  margin-top: 1em;
  background-color: white;
  border-radius: 5px;
  z-index: 200;
  filter: drop-shadow(-1px 5px 9px rgba(0, 0, 0, 0.4));
}
.search-result__container.active {
  animation: fade-in;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  animation-fill-mode: forwards;
}
.search-result__container.closing {
  animation: fade-out;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  animation-fill-mode: forwards;
}
.search-result__container.closed {
  display: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
/* -------------------------- Forms ------------------------------- */
input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0.4em;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  /* Browsers have different default form fonts */
  font-size: 1em;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/
/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Text Inputs
-----------------------------------------------*/
/* Button Controls
-----------------------------------------------*/
input[type=checkbox],
input[type=radio] {
  width: 1em;
  height: 1em;
}

/* File Uploads
-----------------------------------------------*/
/* Search Input
-----------------------------------------------*/
/* Make webkit render the search input like a normal text field */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/
button,
input[type=reset],
input[type=button],
input[type=submit] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/
textarea {
  /* Move the label to the top */
  vertical-align: top;
  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

/* Selects
-----------------------------------------------*/
select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}

/* -------------------------- Form Customization ------------------------------- */
.form-group {
  margin: 0.6em 0;
}
.form-group hr {
  height: 0;
  border-bottom: 1px solid #A2AAB8;
}
.form-group label {
  color: #222325;
  font-weight: 600;
}
.form-group input, .form-group select {
  background: #FFFFFF;
  border: 1px solid #A2AAB88C;
  box-sizing: border-box;
  border-radius: 0.2em;
  width: 100%;
  padding: 0.8em 0.8em;
}
.form-group input::-webkit-input-placeholder, .form-group select::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #A2AAB8;
}
.form-group input::-moz-placeholder, .form-group select::-moz-placeholder {
  /* Firefox 19+ */
  color: #A2AAB8;
}
.form-group input:-ms-input-placeholder, .form-group select:-ms-input-placeholder {
  /* IE 10+ */
  color: #A2AAB8;
}
.form-group input:-moz-placeholder, .form-group select:-moz-placeholder {
  /* Firefox 18- */
  color: #A2AAB8;
}
.form-group input:hover, .form-group input:focus, .form-group select:hover, .form-group select:focus {
  border-color: #222325;
}
.form-group select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='7' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.8533 0.645998C12.9471 0.739629 13 0.866709 13.0001 0.999291C13.0003 1.13187 12.9479 1.2591 12.8543 1.353L7.38926 6.837C7.33814 6.8884 7.27738 6.92919 7.21045 6.95702C7.14352 6.98485 7.07174 6.99918 6.99926 6.99918C6.92677 6.99918 6.855 6.98485 6.78807 6.95702C6.72114 6.92919 6.66037 6.8884 6.60926 6.837L1.14626 1.353C1.09857 1.30681 1.06056 1.25158 1.03445 1.19054C1.00833 1.1295 0.994635 1.06388 0.994154 0.997489C0.993673 0.931101 1.00642 0.865282 1.03164 0.803872C1.05687 0.742462 1.09408 0.686691 1.14109 0.639813C1.1881 0.592934 1.24397 0.555888 1.30546 0.530834C1.36694 0.505781 1.43279 0.493222 1.49918 0.493891C1.56556 0.49456 1.63115 0.508443 1.69212 0.53473C1.75308 0.561017 1.8082 0.599182 1.85426 0.646998L7.00026 5.812L12.1473 0.646998C12.2409 0.55313 12.368 0.500292 12.5005 0.500105C12.6331 0.499917 12.7604 0.552395 12.8543 0.645998H12.8533Z' fill='%23222325'/%3E%3C/svg%3E%0A");
  background-position: right 1em top 50%;
  background-repeat: no-repeat;
}
.form-group.has-error input, .form-group.has-error select {
  border: 2px solid darkred;
}
.form-group.form-checkbox label {
  position: relative;
  margin: 0;
  padding-left: 1.8em;
  cursor: pointer;
  font-weight: normal;
  display: block;
}
.form-group.form-checkbox label::before {
  content: "";
  position: absolute;
  top: 0.2em;
  left: 0;
  width: 1.2em;
  height: 1.2em;
  background-color: #fff;
  border-radius: 0.2em;
  border: 1px solid #A2AAB88C;
  transition: background-color 0.1s ease-in-out;
}
.form-group.form-checkbox input[type=checkbox] {
  display: none;
}
.form-group.form-checkbox input[type=checkbox]:checked + label::before {
  background-color: #222325;
  box-shadow: inset 0 0 1px 1px #fff;
}
.form-group.form-checkbox.form-border label {
  border: 1px solid #A2AAB88C;
  padding: 0.8em 0.8em 0.8em 2.2em;
  border-radius: 0.4em;
  line-height: 1.2;
}
.form-group.form-checkbox.form-border label::before {
  top: 0.9em;
  left: 0.5em;
  width: 1.2em;
  height: 1.2em;
}
.form-group.form-checkbox.form-border:hover label {
  border-color: #222325;
}
.form-group.form-checkbox.form-border input[type=checkbox]:checked + label {
  border-color: #222325;
}
.form-group.form-radio label {
  display: block;
  position: relative;
  border: 1px solid #A2AAB88C;
  cursor: pointer;
  padding: 0.8em 0.8em 0.8em 2.2em;
  font-weight: normal;
  line-height: 1.2;
}
.form-group.form-radio label.has-error {
  border: 2px solid darkred;
}
.form-group.form-radio label .status-checked {
  display: none;
}
.form-group.form-radio label:hover {
  border-color: #222325;
}
.form-group.form-radio label::before {
  content: "";
  position: absolute;
  top: 0.9em;
  left: 0.5em;
  width: 1.2em;
  height: 1.2em;
  background-color: #fff;
  border-radius: 0.6em;
  border: 1px solid #A2AAB88C;
  transition: background-color 0.1s ease-in-out;
}
.form-group.form-radio input[type=radio] {
  display: none;
}
.form-group.form-radio input[type=radio]:checked + label {
  border-color: #222325;
}
.form-group.form-radio input[type=radio]:checked + label .status-price {
  display: none;
}
.form-group.form-radio input[type=radio]:checked + label .status-checked {
  display: block;
}
.form-group.form-radio input[type=radio]:checked + label .status-checked svg {
  width: 1em;
  height: 1em;
}
.form-group.form-radio input[type=radio]:checked + label::before {
  background-color: #222325;
  box-shadow: inset 0 0 1px 1px #fff;
}
.form-group.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0.6em;
  border-radius: 1.5em;
  background-color: #F9F9F9;
  color: #545A6A;
  border: 1px solid #A2AAB88C;
}
.form-group .form-btn {
  background-color: #D5F75C;
  padding: 1em 2em;
  border-radius: 1.5em;
  position: relative;
  transition: background-color 0.1s ease-in-out;
  width: auto;
  color: #1F1F1F;
}
.form-group .form-btn.form-btn-primary {
  background-color: #795AF4;
}
.form-group .form-btn.form-btn-secondary {
  background-color: #222325;
}
.form-group .form-btn.form-btn-white {
  background-color: #FFFFFF;
  color: #222325;
}
.form-group .form-btn.form-btn-white:hover {
  color: #FFFFFF;
}
.form-group .form-btn.order-proceed {
  padding: 1em 2em 1em 4em;
}
.form-group .form-btn.order-proceed:before {
  content: "";
  position: absolute;
  top: 0.7em;
  left: 2em;
  width: 1.4em;
  height: 1.4em;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7992 8.79991C10.7994 9.1723 10.6841 9.53559 10.4692 9.8397C10.2543 10.1438 9.95031 10.3738 9.59922 10.4979V12.9999C9.59922 13.159 9.53601 13.3117 9.42348 13.4242C9.31096 13.5367 9.15835 13.5999 8.99922 13.5999C8.84009 13.5999 8.68748 13.5367 8.57495 13.4242C8.46243 13.3117 8.39922 13.159 8.39922 12.9999V10.4979C8.08813 10.3879 7.81326 10.1945 7.60465 9.93891C7.39603 9.68327 7.26169 9.37521 7.21632 9.04838C7.17095 8.72156 7.21628 8.38855 7.34736 8.08575C7.47845 7.78295 7.69024 7.52201 7.9596 7.33143C8.22896 7.14086 8.54552 7.028 8.87469 7.00517C9.20386 6.98234 9.53297 7.05043 9.82605 7.202C10.1191 7.35356 10.3649 7.58277 10.5366 7.86458C10.7082 8.14639 10.799 8.46995 10.7992 8.79991Z' fill='%23ffffff'/%3E%3Cpath d='M9.33201 0.500759C9.23354 0.43523 9.11789 0.400269 8.99961 0.400269C8.88133 0.400269 8.76568 0.43523 8.66721 0.500759C6.40177 2.02799 3.81935 3.02133 1.11441 3.40596C0.97149 3.42646 0.840756 3.49781 0.746196 3.60692C0.651635 3.71603 0.59959 3.85558 0.599609 3.99996V9.39996C0.599609 14.0692 3.36801 17.476 8.78361 19.5604C8.92261 19.614 9.0766 19.614 9.21561 19.5604C14.6312 17.476 17.3996 14.068 17.3996 9.39996V3.99996C17.3996 3.8554 17.3474 3.7157 17.2526 3.60657C17.1578 3.49743 17.0267 3.4262 16.8836 3.40596C14.1791 3.02115 11.5971 2.02782 9.33201 0.500759ZM1.79961 4.51356C4.17684 4.11093 6.45673 3.2633 8.51961 2.01516L8.99961 1.71636L9.47961 2.01516C11.5425 3.2633 13.8224 4.11093 16.1996 4.51356V9.39996C16.1996 13.4704 13.838 16.4356 8.99961 18.3556C4.16121 16.4356 1.79961 13.4704 1.79961 9.39996V4.51356Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
}
.form-group .form-btn.duplicate-product-in-cart {
  padding: 0.5em 1em;
  background-color: #fff;
  border: 1px solid #222325;
  color: #222325;
}
.form-group .form-btn.disabled {
  background-color: #E3E5E9 !important;
  cursor: default;
}
.form-group .form-btn:hover {
  color: #FCFCFC;
  background-color: #4F4F4F;
}
.form-group .form-btn:hover.form-btn-primary {
  background-color: rgba(121, 90, 244, 0.76);
}
.form-group .form-btn:hover.form-btn-secondary {
  background-color: rgba(34, 35, 37, 0.76);
}
.form-group .form-btn:hover.duplicate-product-in-cart {
  border: 1px solid #795AF4;
  color: white;
}
.form-group .form-btn.btn-block {
  width: 100%;
}

.modal .modal-content {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 1em;
}

/* purgecss start ignore */
/* --------------------------  Rows / Cols ---------------------------- */
.dk-row {
  padding: 0;
  margin: 4em 0;
  /*&:first-child {
      margin-top: 0;
  }*/
}

.dk-col p, .dk-block p {
  margin-bottom: 1em;
}
.dk-col .page-title, .dk-block .page-title {
  font-size: 2em;
}

.dk-block-disabled {
  background-color: rgba(255, 43, 0, 0.5);
}

.dk-block-content.text-content h3 {
  margin: 1rem 0;
}
.dk-block-content.text-content h5, .dk-block-content.text-content h6 {
  font-family: "G Display", sans-serif;
  font-size: inherit;
  font-weight: 400;
  text-transform: uppercase;
}
.dk-block-content.text-content .one-column h5:before, .dk-block-content.text-content .one-column h6:before {
  content: "";
  display: inline-block;
  height: 23px;
  width: 38px;
  margin-right: 0.3em;
  background-image: url("/images/app/demo/category-3.svg");
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.dk-block-content.text-content .image-container {
  background-color: #000;
}
@media (min-width: 768px) {
  .dk-block-content.text-content .image-container {
    border-radius: 20px;
  }
}
.dk-block-content.text-content ul, .dk-block-content.text-content ol {
  padding: 0;
  font-family: "Inter", sans-serif;
  font-size: 1em;
  line-height: 1.4;
  list-style: none;
}
.dk-block-content.text-content ul li, .dk-block-content.text-content ol li {
  position: relative;
  padding-left: 2em;
}
.dk-block-content.text-content ul li::before {
  content: "•";
  position: absolute;
  left: 0;
  top: -0.25em;
  color: #222325;
  font-size: 1.4em;
  font-weight: bold;
}
.dk-block-content.text-content ol {
  counter-reset: li;
}
.dk-block-content.text-content ol li {
  counter-increment: li;
}
.dk-block-content.text-content ol li::before {
  content: counter(li) ".";
  position: absolute;
  left: 0;
  top: 0.15em;
  color: #222325;
  font-size: 0.9em;
  font-weight: bold;
}
.dk-block-content.text-content .table-responsive {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 1em 0 rgba(34, 35, 37, 0.15);
  box-shadow: 0 0 1em 0 rgba(34, 35, 37, 0.15);
}
.dk-block-content.text-content table:not(.custom-style) {
  table-layout: fixed;
  overflow-wrap: break-word;
  width: 100%;
  -webkit-box-shadow: 0 0 1em 0 rgba(34, 35, 37, 0.15);
  box-shadow: 0 0 1em 0 rgba(34, 35, 37, 0.15);
}
.dk-block-content.text-content table:not(.custom-style) thead tr {
  background-color: #222325;
  color: #FFFFFF;
}
.dk-block-content.text-content table:not(.custom-style) thead tr th {
  padding: 1.25em 1.5em;
}
.dk-block-content.text-content table:not(.custom-style) tbody tr:nth-child(even) {
  background-color: rgba(34, 35, 37, 0.05);
}
.dk-block-content.text-content table:not(.custom-style) tbody tr.grey {
  background-color: rgba(34, 35, 37, 0.05);
}
.dk-block-content.text-content table:not(.custom-style) tbody tr td {
  padding: 0.75em 1.5em;
}
.dk-block-content.text-content table:not(.custom-style).not-striped tbody tr:nth-child(odd), .dk-block-content.text-content table:not(.custom-style).not-striped tbody tr:nth-child(even) {
  background-color: #FFFFFF;
}
@media (max-width: 991px) {
  .dk-block-content.text-content .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0)), linear-gradient(-90deg, white 0%, rgba(255, 255, 255, 0)) 100% 0, linear-gradient(90deg, rgba(34, 35, 37, 0.15), rgba(34, 35, 37, 0)), linear-gradient(-90deg, rgba(34, 35, 37, 0.15), rgba(34, 35, 37, 0)) 100% 0;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    background-size: 100px 100%, 100px 100%, 1.5em 100%, 1.5em 100%;
    background-attachment: local, local, scroll, scroll;
  }
  .dk-block-content.text-content .table-responsive table {
    table-layout: auto;
  }
}

.dk-block-tabs .dk-block-tabs-head-link {
  color: #222325;
  font-weight: 600;
  border: 1px solid #fff;
  border-radius: 32px;
}
.dk-block-tabs .dk-block-tabs-head-link.active {
  color: #222325;
  background-color: #fff;
  border-color: #222325;
}
.dk-block-tabs .dk-block-tabs-head-link:hover {
  color: #fff;
  background-color: #222325;
  border-color: #222325;
}
.dk-block-tabs a {
  display: block;
  width: 100%;
  /* blocco del prodotto più piccolo */
}
.dk-block-tabs a .dk-block-tabs-product {
  background-color: #f8f8f9;
  border-radius: 1em;
  padding: 2em;
}
.dk-block-tabs a .dk-block-tabs-product figure {
  height: 18em;
  transition: all 0.15s ease-in-out;
}
.dk-block-tabs a .dk-block-tabs-product figure img {
  width: auto;
  height: 100%;
  max-height: 18em;
}
.dk-block-tabs a .dk-block-tabs-product .product-name-container {
  height: 3em;
  line-height: 3em;
  transition: all 0.15s ease-in-out;
}
.dk-block-tabs a .dk-block-tabs-product .product-name-container .product-name {
  font-size: 2em;
  font-weight: 600;
  transition: all 0.15s ease-in-out;
}
.dk-block-tabs a .dk-block-tabs-product .product-description-container {
  height: 3em;
  transition: all 0.15s ease-in-out;
}
.dk-block-tabs a .dk-block-tabs-product .product-description-container .product-description {
  line-height: 3em;
}
.dk-block-tabs a .dk-block-tabs-product .product-description-container .product-cta {
  display: none;
}
.dk-block-tabs a:hover .dk-block-tabs-product figure {
  height: 17em;
}
.dk-block-tabs a:hover .dk-block-tabs-product .product-name-container {
  height: 3em;
}
.dk-block-tabs a:hover .dk-block-tabs-product .product-description-container {
  height: 4em;
}
.dk-block-tabs a:hover .dk-block-tabs-product .product-description-container .product-description {
  display: none;
}
.dk-block-tabs a:hover .dk-block-tabs-product .product-description-container .product-cta {
  display: block;
}
.dk-block-tabs a.dk-block-tabs-small {
  font-size: inherit;
}
@media (min-width: 992px) {
  .dk-block-tabs a.dk-block-tabs-small {
    font-size: 0.885em;
  }
  .dk-block-tabs a.dk-block-tabs-small .dk-block-tabs-product {
    padding: 1em;
  }
  .dk-block-tabs a.dk-block-tabs-small .dk-block-tabs-product figure {
    height: 9em;
  }
  .dk-block-tabs a.dk-block-tabs-small .dk-block-tabs-product figure img {
    max-height: 9em;
  }
  .dk-block-tabs a.dk-block-tabs-small .dk-block-tabs-product .product-name-container {
    height: 2em;
    line-height: 2em;
  }
  .dk-block-tabs a.dk-block-tabs-small .dk-block-tabs-product .product-name-container .product-name {
    font-size: 1.5em;
  }
  .dk-block-tabs a.dk-block-tabs-small .dk-block-tabs-product .product-description-container {
    height: 2em;
  }
  .dk-block-tabs a.dk-block-tabs-small .dk-block-tabs-product .product-description-container .product-description {
    line-height: 2em;
  }
  .dk-block-tabs a.dk-block-tabs-small .dk-block-tabs-product .product-description-container .product-cta {
    margin: 0;
  }
  .dk-block-tabs a.dk-block-tabs-small .dk-block-tabs-product .product-description-container .product-cta .form-btn {
    font-size: 0.875em;
    padding: 0.7em 1em;
  }
  .dk-block-tabs a.dk-block-tabs-small:hover .dk-block-tabs-product figure {
    height: 8em;
  }
  .dk-block-tabs a.dk-block-tabs-small:hover .dk-block-tabs-product .product-name-container {
    height: 2em;
  }
  .dk-block-tabs a.dk-block-tabs-small:hover .dk-block-tabs-product .product-description-container {
    height: 3em;
  }
}

@media (max-width: 767px) {
  .dk-block-top-offers .tab-content {
    padding: 0;
  }
}
.dk-block-top-offers .dk-block-top-offers-head-link img {
  max-width: 100px;
  max-height: 50px;
  width: 100%;
  filter: grayscale(100%);
  opacity: 0.1;
}
.dk-block-top-offers .dk-block-top-offers-head-link.active img {
  filter: grayscale(0);
  opacity: 1;
}

/* purgecss end ignore */