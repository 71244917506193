/* ------------------------ General Layout ------------------------ */

html, body {
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    position: relative;
    color: $gray;
    background-color: $white;
}

body {
    overflow-x: hidden;
}

strong{
    font-weight: 700;
}

a {
    color: $gray;
    display: inline-block;
    text-decoration: none;
    transition: all .25s ease-in-out;
    padding: 0 1px;

    &:hover {
        color: $black;
        text-decoration: underline;
        outline: 0;
        transition: all .25s ease-in-out;
    }

    &:focus {
        text-decoration: underline;
        outline: 0;
    }

    &.no-underline {
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

p {
    font-size: 1em;
    line-height: 1.4;
    margin-bottom: 2em;
}

h1, h2, h3, h4, h5, h6 {
    color: $black;
    font-weight: 600;
}

.font-semibold{
    font-weight: 600;
}

.font-bold{
    font-weight: 700;
}

h1 {
    font-size: 4em;
    line-height: 1.05;
}

h2 {
    font-size: 3em;
    line-height: 1.075;
}

h3 {
    font-size: 2em;
    line-height: 1.5;
}

h4 {
    font-size: 1.5em;
    line-height: 1.4;
}

h5 {
    font-size: 1.25em;
    line-height: 1.2;
}

h6 {
    font-size: 1em;
    line-height: 1.2;
}

figure {
    margin: 0;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

//hr {
//    height: 0;
//    border-bottom: .3em solid #47ad9c;
//}

.background-round {
    border-radius: 8px;
    padding: 2em;

    &.background-round-no-padding {
        padding: .2em .6em;
    }
}

.round-card{
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 1em;
    padding: 1.5em;
}

.x-btn {
    font-family: 'G Display', sans-serif;
    padding: 0.8em 1em;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid $black;
    border-radius: 16px !important;
    position: relative;
    transition: 0.1s ease-in-out;
}

.x-btn-lg {
    width: 45%;
    //padding: 0.8em 6em;

    @media screen and (max-width: $breakpoint-xs-max) {
        width: auto;
        //padding: 0.8em 1em;
    }
}

.x-btn-primary {
    color: $black !important;
    background-color: $lime !important;

    svg {
        fill: $black;

        path {
            fill: $black;
        }
    }

    &:hover {
        color: $white !important;
        background-color: $black !important;

        svg {
            fill: $white;

            path {
                fill: $white;
            }
        }
    }
}

.x-btn-secondary {
    color: $white !important;
    background-color: $black !important;

    svg {
        fill: $white;

        path {
            fill: $white;
        }
    }

    &:hover {
        color: $black !important;
        background-color: $lime !important;

        svg {
            fill: $black;

            path {
                fill: $black;
            }
        }
    }
}

.x-font-primary {
    font-family: 'Sora', sans-serif;
}

.x-font-secondary {
    font-family: 'G Display', sans-serif;
}

.alert{
    @extend .background-round;
    border-radius: .4em;
    background-color: #fbc8c8;
    color: #cf122b;
    padding: .4em 1em;
    ul{
        margin: 0;
        padding:0;
        list-style: none;
    }

    &.alert-ok{
        background-color: #DCFFE6;
        color: #139b35;
    }
}

@media screen and (min-width: $breakpoint-md-min) {
    body {
        font-size: 16px;
    }
}

// Normal desktop
@media screen and (min-width: $breakpoint-lg-min) {
    body {
        font-size: calc(7px + 6 * ((100vw - 476px) / 600));
    }
}

// Full HD desktops
@media screen and (min-width: $breakpoint-hd-min) {
    body {
        font-size: 16px;
    }
}

// 2K desktops
@media screen and (min-width: $breakpoint-2k-min) {
    body {
        font-size: 18px;
    }
}

.btn:focus {
    box-shadow: unset;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .img-filter:after {
        content: '';
        background: rgba(71, 173, 156, 0.51);
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }
}

/* -------------------------- Slider ------------------------------------ */

.swiper-button-next, .swiper-button-prev {
    &::after{
        content: '';
        width: 1em;
        height: .5em;
        background-repeat: no-repeat;
        background-size: contain;
        transition: opacity .15s ease-in-out;
    }

    &:hover{
        &::after{
            opacity: 0.4;
        }
    }
}

.swiper-button-next::after, .swiper-rtl .swiper-button-prev::after {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.233675 0.635177C0.383485 0.484988 0.586812 0.400447 0.798943 0.400147C1.01107 0.399847 1.21464 0.483812 1.36487 0.633577L10.1393 9.37758C10.2213 9.45933 10.2863 9.55646 10.3307 9.6634C10.3751 9.77034 10.398 9.88499 10.398 10.0008C10.398 10.1166 10.3751 10.2312 10.3307 10.3382C10.2863 10.4451 10.2213 10.5422 10.1393 10.624L1.36487 19.368C1.21379 19.5135 1.01159 19.5939 0.801826 19.5918C0.592064 19.5896 0.391524 19.5052 0.243401 19.3567C0.0952792 19.2081 0.0114276 19.0074 0.00990757 18.7976C0.00838751 18.5878 0.0893206 18.3859 0.235275 18.2352L8.49928 9.99998L0.235275 1.76638C0.0850853 1.61657 0.000544986 1.41324 0.000244942 1.20111C-5.51024e-05 0.988977 0.0839098 0.785411 0.233675 0.635177Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: center left;
}
.swiper-button-prev::after, .swiper-rtl .swiper-button-next::after {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7664 19.3648C10.6166 19.515 10.4133 19.5995 10.2011 19.5998C9.98901 19.6001 9.78545 19.5162 9.63522 19.3664L0.860815 10.6224C0.778576 10.5406 0.713312 10.4434 0.668778 10.3363C0.624244 10.2292 0.601318 10.1144 0.601318 9.99842C0.601318 9.88244 0.624244 9.7676 0.668778 9.66052C0.713312 9.55343 0.778576 9.4562 0.860815 9.37442L9.63522 0.632019C9.7863 0.486492 9.9885 0.40613 10.1983 0.408244C10.408 0.410357 10.6086 0.494777 10.7567 0.643318C10.9048 0.79186 10.9887 0.992639 10.9902 1.20241C10.9917 1.41217 10.9108 1.61415 10.7648 1.76482L2.50402 10L10.7664 18.2352C10.9166 18.385 11.0011 18.5884 11.0014 18.8005C11.0017 19.0126 10.9178 19.2162 10.768 19.3664L10.7664 19.3648Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: center right;
}

.swiper-pagination-bullet {
    width: 5em;
    height: .2em;
    border-radius: .5em;
    background-color: #fff;

    &.swiper-pagination-bullet-active {
        background-color: $lime;
    }
}

.swiper-scrollbar {
    background: none;
}

.swiper-slide {
    img {
        width: 100%;
    }
}

//.swiper-slide{
//    .swiper-slide-container{
//        position: relative;
//
//        .swiper-slide-img-container{
//            position: absolute;
//            top: 0;
//            left: 0;
//            width: 100%;
//            height: 100%;
//            overflow: hidden;
//            img {
//                height: 100%;
//                width: auto;
//                max-width: none;
//                transform: translate(-50%,0) !important;
//            }
//        }
//
//        .swiper-slide-content{
//            color: $font-white;
//            text-align: center;
//            padding:2em 0;
//            min-height: 30vh;
//            background-size: cover;
//            background-position: center;
//
//            .slide-title{
//                font-size: 2.5em;
//                font-weight: 600;
//            }
//            .slide-description{
//                font-size: 1.2em;
//                //text-shadow: -1px 0px 3px $font-grey, 1px 0px 3px $font-grey, -1px 0px 3px $font-grey, 1px 0px 3px $font-grey;
//            }
//            .slide-cta{
//                margin-top:2em;
//                .form-btn{
//                    background-color: $font-white;
//                    color: $font-black;
//                    &:hover{
//                        background-color: #d7d7d7;
//                        color: $font-black;
//                    }
//                }
//            }
//        }
//    }
//
//    @media screen and (min-width: $breakpoint-lg-min) {
//        .swiper-slide-container{
//
//            .swiper-slide-img-container{
//                position: relative;
//                width: 100%;
//                height: auto;
//                img {
//                    width: 100%;
//                    height: auto;
//                    max-width: none;
//                    transform: none !important;
//                }
//            }
//            .swiper-slide-content{
//                top: 40% !important;
//                background: none !important;
//                position: absolute !important;
//                transform: translate(-50%,-50%) !important;
//                left: 50% !important;
//                top: 50% !important;
//            }
//        }
//    }
//}

/* -------------------------- Header ------------------------------------ */
#header {
    height: 90px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);


    .header-main-menu {
        height: 100%;
        padding: 0;
    }

    .main-nav {
        height: 100%;

        & > li {
            height: 100%;
            display: flex;
            align-items: center;

            //&.dropdown:hover .dropdown-menu {
            //    display: flex;
            //}
        }
    }

    .navbar-link {
        margin: 0 0.3em;
        padding: .3em 0.6em;

        &:after {
            content: none;
        }

        &:hover {
            text-decoration: none;
            border-radius: 8px;
            outline: 0;
            background-color: $lime;
            transition: all .25s ease-in-out;
        }
    }

    .dropdown-menu {
        width: 100%;
        padding: 2em 0;
        margin: 0;
        border: none;
        border-top: 1px solid #000;
        border-radius: 0;
        top: 100%;
        left: 0;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

        .dropdown-column {
            &:not(:first-child) {
                border-left: 1px solid $light-gray;
            }
        }

        .dropdown-column-title {
            font-weight: bold;
        }

        .dropdown-list-item {
            display: flex;

            & > * {
                padding: .3em 0.8em;
                margin-bottom: .5em;
            }

            &:last-child {
                text-decoration: underline;
            }
        }
    }

    .header-cart-icons{
        & > *:not(:last-child) {
            @media (max-width: $breakpoint-md-max) {
                margin-right: 1.2em;
            }
        }

        a {
            svg {
                //width: auto;
                //height: 2em;
                //margin-left: .5em;
            }

            &:hover {
                svg {
                    rect {
                        //fill: $brick-light;
                    }
                }
            }
        }

        .header-cart-icons-divisor {
            width: 1px;
            height: 20px;
            background-color: $gray;
        }

        .cart-counter {
            position: absolute;
            font-size: 10px;
            font-weight: 700;
            background: $lime;
            padding: 4px;
            width: 2em;
            height: 2em;
            text-align: center;
            border-radius: 50%;
            line-height: 1.2em;
            bottom: -1em;
            right: -0.5em;
        }
    }

    .secure-payment {
        svg {
            @media (max-width: $breakpoint-sm-max) {
                padding: 2px;
                box-sizing: content-box;
                border-radius: 6px;
                background-color: $lime;
            }
        }
    }

    .mobile-menu-button, .desktop-menu-button{
        cursor:pointer;
        svg{
            transition: all .25s ease-in-out;
        }
    }

    #mobile-menu {
        display: none;
        position: fixed;
        top: 90px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow-y: scroll;

        .mobile-menu-accordion {
            padding: 0 1em;

            .mobile-nav-link {
                width: 100%;
                padding: .7rem 1rem;
                font-size: 1.6rem;
                font-weight: 600;
                color: #212529;
                text-align: left;
                background-color: #fff;
                border: 0;
                border-radius: 8px;
                overflow-anchor: none;
                box-shadow: none;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;

                &:focus {
                    font-weight: 600;
                    background-color: $lime;
                }
            }

            &.accordion {
                .accordion-item {
                    border: 0;
                    border-bottom: 0;
                    border-radius: 0;

                    &.border-bottom {
                        border-bottom: 1px solid black !important;
                    }

                    .accordion-header {
                        margin-bottom: 0;
                    }

                    .accordion-button {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: .7rem 1rem;
                        font-size: 1.6rem;
                        color: #212529;
                        text-align: left;
                        background-color: #fff;
                        border: 0;
                        border-radius: 8px;
                        overflow-anchor: none;
                        box-shadow: none;
                        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;

                        &::after {
                            flex-shrink: 0;
                            width: 2rem;
                            height: 2rem;
                            margin-left: auto;
                            content: "";
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                            background-repeat: no-repeat;
                            background-size: 2rem;
                        }

                        &:not(.collapsed) {
                            font-weight: 600;
                            background-color: $lime;
                        }

                        &.menu-mobile-level-1 {
                            font-weight: 600;
                        }
                    }

                    .accordion-body {
                        padding: 0;

                        &.menu-mobile-level-1 {
                            .accordion-button {
                                font-size: 1.4rem;
                            }
                        }

                        &.menu-mobile-level-2 {
                            font-size: 1.2rem;

                            a {
                                display: block;
                                padding: 0.5rem 1.25rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

body{
    &.mobile-menu-open{
        overflow: hidden;
        #header{
            .mobile-menu-button{
                svg{
                    transform: rotate(90deg);
                }
            }
            #mobile-menu{
                display: block;
            }
        }
    }

    &.desktop-menu-open{

        a{
            cursor:pointer;
        }

        #header{
            .desktop-menu-button{
                svg{
                    transform: rotate(90deg);
                }
            }
            #desktop-menu{
                display: block;
            }
        }

        .desktop-menu-overlay{
            position: absolute;
            top:0;
            left: 0;
            width:100vw;
            height:100%;
            background-color: rgba(0, 0, 0, 0.53);
            z-index:50;
        }
    }
}

/* -------------------------- Breadcrumb ------------------------------------ */

.breadcrumb {
    padding: 1.5em 0;

    a {
        color: $breadcrumb-link;
    }

    span:last-child {
        font-weight: 600;
        color: $black;
    }

    .breadcrumb-separator {
        margin: 0 .5em;
    }
}

/* -------------------------- Footer ------------------------------------ */

#footer {
    .newsletter-bg {
        padding: 6em 0;
        background-image: url('/images/app/newsletter-bg.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;

        @media (max-width: $breakpoint-md-max) {
            padding: 5em 0;
            background-size: 600px;
            background-position: right bottom;
        }

        @media (max-width: $breakpoint-sm-max) {
            padding: 2em 0 20em;
            background-image: url('/images/app/newsletter-bg-mobile.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
        }

        @media (max-width: $breakpoint-xs-max) {
            padding: 2em 0 15em;
        }
    }

    .newsletter-form {
        .newsletter-email-input,
        .btn {
            padding: 0.7em 1.3em;
        }

        .newsletter-email-input {
            width: 50%;
            line-height: 1.5;
            border: 1px solid $black;
            border-radius: 12px 0 0 12px;
            background-color: #fff;

            @media (max-width: $breakpoint-xs-max) {
                width: 60%;
            }
        }

        .btn {
            font-family: 'G Display', sans-serif;
            font-size: .7em;
            display: flex;
            align-items: center;
            border-radius: 0 12px 12px 0;
        }

        .result-message {
            display: none;
            font-weight: bold;
            font-size: .9em;
        }
    }

    .footer-links {
        padding: 2em 0;
        font-size: 1em;

        a {
            color:$font-grey;
            margin-bottom: 1em;
            display: table;

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                margin-right: .4em;
            }
        }
    }

    .footer-bottom {
        padding: 1.4em 0;
        border-top: 1px solid #BDBDBD;
    }

    .footer-social {
        a, span {
            margin:0 .2em;
        }
    }

    &.footer-checkout {
        background-color: #f8f8f8;
        padding: 1.4em 0;
        .footer-copy {
            margin:0;
        }
    }
}
/* purgecss start ignore */




/* -------------------------- Pages ------------------------------------- */

#page-profile {
    .dashboard-sidebar {
        list-style: none;
        padding: 0;

        li {
            padding: 1em 0;
            border-bottom: 1px solid $light-gray;
            margin-right: 1em;

            &:first-child {
                border-top: 1px solid $light-gray;
            }

            a:hover, a.active {
                font-weight: bold;
            }
        }
    }

    .checkout-form-title {
        font-size: 1.4em;
        font-weight: 600;
    }

    .invoice-field-title {
        display: inline-block;
        text-decoration: underline;
        cursor: pointer;
        transition: .1s;

        &.disabled {
            font-size: 1em;
            font-weight: 400;
            color: $light-gray;
            text-decoration: none;
            transition: .3s;
        }
    }

    .form-group {
        label.required {
            &:after {
                content: '*';
            }
        }
    }

    .orders {
        .order-item {
            &:last-child {
                .divisor {
                    display: none;
                }
            }
        }

        .product-image {
            img {
                width: 100%;
                border-radius: 1em;
            }
        }

        .product-description {
            p {
                margin-bottom: 0;
            }
        }

        .product-category {
            font-family: 'G Display Extended', sans-serif;
            font-size: .7em;
            display: inline-block;
            padding: .2em .6em;
            text-transform: uppercase;
            border-radius: 6px;
            color: $black;
            background-color: $lime;
        }

        .download-btn {
            .x-btn {
                @media (min-width: $breakpoint-lg-min) {
                    border-radius: 50% !important;
                }
            }
        }
    }

    .shipping-address, .invoice-address, .payments-method {
        span {
            font-size: .8em;
        }
    }
}

#page-catalog {
    .catalog-header {
        select {
            background-color: #fff;
            border: 1px solid #e3e5e9;

            @media (max-width: $breakpoint-sm-max) {
                width: 200px;
            }
        }
    }

    .catalog-container{
        .filters-container {
            .accordion {
                .accordion-item {
                    border: none;
                    border-bottom: 1px solid $light-gray;
                    background-color: transparent;

                    @media (max-width: $breakpoint-md-max) {
                        border-color: $gray;
                    }

                    .accordion-button {
                        padding: 0;
                        font-size: 1em;
                        font-weight: 600;
                        color: $black;
                        background-color: transparent;
                        box-shadow: none;

                        &::after {
                            width: .85em;
                            height: .85em;
                            background-size: .85em;
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                        }

                        @media (max-width: $breakpoint-md-max) {
                            color: #fff;

                            &::after {
                                background-image: url("/images/app/demo/arrow-down-white.svg");
                            }
                        }
                    }

                    .accordion-body {
                        padding: 0;
                    }

                    .checkbox {
                        line-height: 1.1;

                        label {
                            font-size: .8em;
                            margin: 0;
                            padding-left: 2.5em;
                            position: relative;
                            cursor: pointer;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                                width: 14px;
                                height: 14px;
                                border: 1px solid $gray;
                                border-radius: 4px;
                                background-color: transparent;
                                transition: background-color .1s ease-in-out;

                                @media (max-width: $breakpoint-md-max) {
                                    border-color: #fff;
                                }
                            }

                            &:hover{
                                color: $black;
                                font-weight: 600;

                                @media (max-width: $breakpoint-md-max) {
                                    color: $lime;
                                }
                            }
                        }

                        input[type=checkbox] {
                            display: none;

                            &:checked + label {
                                color: $black;
                                font-weight: 600;

                                @media (max-width: $breakpoint-md-max) {
                                    color: $lime;
                                }
                            }

                            &:checked + label::before {
                                border-color: $black;
                                background-color: $lime;
                                box-shadow: inset 0 0 1px 1px #fff;

                                @media (max-width: $breakpoint-md-max) {
                                    border-color: #fff;
                                    box-shadow: inset 0 0 1px 1px $black;
                                }
                            }
                        }
                    }

                }
            }
        }

        .catalog {
            .product {
                position: relative;
                display: flex;
                min-height: 390px;
                font-size: 1em;
                border-radius: 1em;
                background-color: #fff;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
                transition: .3s;

                a {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                .tag-container {
                    z-index: 2;
                    position: absolute;
                    top: 1em;
                    left: 1em;

                    .tag-content {
                        .tag {
                            font-size: .8em;
                            padding: .25em .5em;
                            text-transform: uppercase;
                            line-height: initial;
                            color: $black;
                            border: 1px solid $black;
                            border-radius: .5em;
                            background-color: $white;
                        }
                    }
                }

                .image-container{
                    figure {
                        overflow: hidden;
                        border-radius: 1em;
                    }

                    img {
                        width: 100%;
                        border-radius: 1em;
                        transition: .3s;
                    }
                }

                .product-content {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    padding: 1em;
                }

                .category-container {
                    font-family: 'G Display', sans-serif;
                    font-size: .7em;
                    text-transform: uppercase;
                }

                .name-container {
                    display: flex;
                    flex-grow: 1;
                }

                .subcategories-container {
                    .subcategories-content {
                        font-family: 'G Display', sans-serif;
                        font-size: 0.7em;
                    }

                    .subcategory-pick {
                        display: inline-block;
                        border-radius: 6px;
                        padding: .2em .6em;
                        text-transform: uppercase;
                        background-color: $lime;
                    }
                }

                hr {
                    border: 1px solid $light-gray;
                }

                .price-container {
                    font-size: 0.8em;

                    .price-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .final-price {
                            font-size: 1.4em;
                            margin-right: 5px;
                        }

                        .original-price {
                            color: $light-gray;
                            text-decoration:line-through;
                        }
                    }
                }

                &:hover{
                    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08);
                    transition: .3s;

                    .image-container {
                        overflow: hidden;

                        img {
                            transform: scale(1.05);
                            transition: .3s;
                        }
                    }
                }
            }
        }

    }

    .mobile-filter-button{
        cursor: pointer;
        display: flex;
        align-items: center;
        color: $black;
        border-radius: 8px;
        padding: .5em;
        font-size: 1.1em;
        transition: 0.1s ease-in-out;

        &.open-filters {
            background-color: #fff;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

            &:hover {
                background-color: $lime;
            }
        }

        svg {
            margin-right: .7em;
        }

    }
}


#page-product {
    .mobile-fixed-price {
        z-index: 99;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: #fff;
        box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.08);

        .final-price {
            color: $black;
            font-size: 1.8em;
            font-weight: bold;
            margin-right: 5px;

            //&.full-price {
            //    color: $light-gray;
            //}
        }

        .original-price {
            color: $light-gray;
            text-decoration: line-through;
            font-size: .9em;
        }
    }

    .product-container {
        margin-bottom: 5em;

        .product-title-container {
            padding-bottom: 1em;
            border-bottom: 1px solid $light-gray;

            h1 {
                font-size: 2em;
            }
        }

        .product-image-container {
            img {
                width: 100%;
                border-radius: 24px;
            }
        }

        .product-video {
            iframe {
                width: 100%;
            }

            .video-caption {
                font-size: .8em;
            }

            h4 {
                margin-bottom: 1.5rem;
            }

            ul {
                @media (min-width: $breakpoint-sm-min) {
                    columns: 2;
                }

                li {
                    margin-bottom: 1rem;
                    &:before {
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        margin-right: 1em;
                        background: $lime;
                        border: 1px solid $black;
                        border-radius: 50%;
                    }
                }
            }

            ul, ol {
                margin-bottom: 3rem;
            }
        }

        .product-tags {
            .product-tags-list {
                display: flex;

                .product-tag {
                    display: inline-block;
                    font-size: .7em;
                    padding: .2em .6em;
                    margin-right: .5em;
                    text-transform: uppercase;
                    border-radius: 6px;
                    color: $white;
                    background-color: $gray;
                }
            }
        }

        .product-selections-container {
            position: sticky;
            top: 10em;

            @media (max-width: $breakpoint-sm-max) {
                position: static;
            }

            .selection-summary {
                .final-price {
                    color: $black;
                    font-size: 1.8em;
                    font-weight: bold;
                    margin-right: 5px;

                    //&.full-price {
                    //    color: $light-gray;
                    //}
                }

                .original-price {
                    color: $light-gray;
                    text-decoration: line-through;
                    font-size: .9em;
                }
            }

            .product-features {
                .product-feature-container {
                    position: relative;
                    padding-left: 1.3em;
                    border-left: 2px solid $black;

                    &:last-child {
                        border-color: transparent;
                    }

                    &:not(:last-child) {
                        padding-bottom: 1.2em;
                    }
                }

                .product-feature-point {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $black;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translateX(-60%);
                }

                .product-category-container {
                    transform: translateY(-4px);
                }

                .product-feature-title {
                    font-size: .8em;
                    color: $black;
                }

                .product-category {
                    font-family: 'G Display Extended', sans-serif;
                    font-size: .7em;
                    //font-weight: 600;
                    display: inline-block;
                    padding: .2em .6em;
                    text-transform: uppercase;
                    border-radius: 6px;
                    color: $black;
                    background-color: $lime;
                }
            }

            .product-terms {
                .product-terms-list {
                    font-size: .9em;

                    .list-point {
                        display: inline-block;
                        margin-right: .7em;
                    }
                }
            }

            .notes-description {
                font-size:.8em;
                margin: .6em 0;

                .note {
                    padding-left: .6em;
                    line-height: 1.2;
                    color: $gray;
                    border-left: 2px solid $black;

                    p {
                        display: inline;
                    }
                }
            }

            .cart-button-container {
                //padding-bottom: 1em;

                .cart-button {
                    display: block;

                    &.disabled {
                        cursor: not-allowed;
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

#page-checkout {
    padding-bottom:5em;

    .checkout-steps {
        margin: 2em 0;

        @media screen and (max-width: $breakpoint-md-max) {
            font-size:.8em;
        }

        .checkout-step {
            display: flex;
            align-items: center;
            opacity: 0.3;

            @media screen and (max-width: $breakpoint-xs-max) {
                flex-direction: column;
                align-items: center;
            }

            .icon-step {
                margin-right: .3em;

                @media screen and (max-width: $breakpoint-xs-max) {
                    margin-right: 0;
                }
            }

            &.active {
                opacity: 1;
            }

            &.separator {
                margin: 0 1em;

                @media screen and (max-width: $breakpoint-xs-max) {
                    margin: 0 .5em;
                    width: 65px;
                }
            }
        }

    }

    .checkout-form-container {
        background-color: #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
        border-radius: 1em;
        padding: 1.5em;

        .registration-notice {
            padding: 1em;
            border-radius: 1em;
        }

        .checkout-form-title {
            font-size: 1.4em;
            font-weight: 600;
        }

        .invoice-field-title {
            display: inline-block;
            text-decoration: underline;
            cursor: pointer;
            transition: .1s;

            &.disabled {
                font-size: 1em;
                font-weight: 400;
                color: $light-gray;
                text-decoration: none;
                transition: .3s;
            }
        }

        .form-group {
            label.required {
                &:after {
                    content: '*';
                }
            }
        }

        .checkout-form-recap {
            margin-top:1em;
            margin-bottom:1em;

            .checkout-form-recap-content {
                font-size: 0.9em;

                .credential {
                    color: $black;
                    margin-left: 0.8em;
                }
            }

            &.checkout-form-recap-contact {
                padding-right: 2em;
            }

            &.checkout-form-recap-invoice {
                border-left: 1px solid #e3e5e9;
                padding-left: 2em;
            }
        }

        @media screen and (max-width: $breakpoint-md-max) {
            .checkout-form-recap {
                &.checkout-form-recap-contact {
                    padding-right: 0;
                }

                &.checkout-form-recap-invoice {
                    border: none;
                    padding-left: 0;
                }
            }
        }

        .accept-policy {
            color: $light-gray;
            font-size: .8em;
            font-style: italic;
        }
    }

    .checkout-summary {
        background-color: #fff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
        border-radius: 1em;
        padding: 1.5em;
        margin-top: 2em;
        top: 5em;

        @media (max-width: $breakpoint-md-max) {
            margin-top: 0;
        }

        &.sticky-lg-top {
            z-index: 1019;
        }

        .checkout-summary-close {
            .close-checkout-summary {
                cursor:pointer;
            }
        }

        .checkout-summary-title {
            font-size: 1.2em;
            font-weight: 600;
        }

        .insert-coupon {
            .coupon-input {
                border-color: $black;
            }
            .coupon-btn {
                margin-left: .6em;
            }
        }

        .checkout-summary-product-list {
            font-size: .9em;

            .image-container {
                width: 6em;
                margin-right: .5em;

                img {
                    width: 100%;
                    border-radius: 8px;
                }
            }
        }

        .product-category {
            font-family: 'G Display Extended', sans-serif;
            font-size: .7em;
            display: inline-block;
            padding: .2em .6em;
            text-transform: uppercase;
            border-radius: 6px;
            color: $black;
            background-color: $lime;
        }

        .checkout-summary-totals {
            .total {
                color: $black;
                font-size: 1.8em;
            }

            .total-save {
                font-size:.8em;
            }
        }

        .form-control {
            border-radius: 0.5em;
        }
    }

    .checkout-step2 {
        .payment-method {
            border:0;

            > .form-group {
                label {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid $light-gray;
                    border-radius: 0.3em;
                }
            }

            input[type="radio"]:checked + label {
                border: 1px solid $black;
            }
        }

        .form-group {
            &.form-radio {
                label {
                    //border: 0;
                    font-size: .9em;
                    margin: 0;
                    padding: 1.3em 0.8em 1.3em 3em;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        transform: translateY(-50%);
                        width: 14px;
                        height: 14px;
                        border: 1px solid $gray;
                        border-radius: 4px;
                        background-color: transparent;
                        transition: background-color .1s ease-in-out;
                    }

                    &:hover{
                        color: $black;
                        font-weight: 600;
                        border-color: $black;
                    }
                }

                input[type=radio] {
                    display: none;

                    &:checked + label {
                        color: $black;
                        font-weight: 600;
                    }

                    &:checked + label::before {
                        border-color: $black;
                        background-color: $lime;
                        box-shadow: inset 0 0 1px 1px #fff;
                    }
                }
            }
        }
    }
}

#page-cart{
    padding-bottom: 5em;

    .cart-summary-product-container,
    #upsellProductsModal {
        .item-round-card {
            &:not(:last-child) {
                margin: 0.6em 0;
            }

            .round-card {
                width: 100%;
            }
        }

        .product-image img {
            width: 100%;
            border-radius: 1em;
        }

        .product-category {
            font-family: 'G Display Extended', sans-serif;
            font-size: .7em;
            display: inline-block;
            padding: .2em .6em;
            text-transform: uppercase;
            border-radius: 6px;
            color: $black;
            background-color: $lime;
        }

        .product-description {
            font-size: .9em;

            p {
                margin-bottom: 1rem;
            }
        }

        .add-upsell-product-label-container {
            @media (min-width: $breakpoint-lg-min) {
                text-align: right;
            }
        }

        .remove-product,
        .add-upsell-product {
            display: none;
        }

        .remove-product-label,
        .add-upsell-product-label {
            color: inherit;
            font-size: .9em;
            font-weight: bold;
            cursor: pointer;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            .trash-icon {
                margin-left: .3em;
            }
        }

        .product-price {
            @media (min-width: $breakpoint-lg-min) {
                text-align: right;
            }
        }

        .final-price {
            color: $black;
            font-size: 1.5em;
            font-weight: bold;
        }

        .original-price {
            color: $light-gray;
            text-decoration: line-through;
            font-size: .9em;
        }

        .tag-product-added {
            margin-left: 1em;
            font-size: 0.8em;
            background-color: #EBF6FA;
            color: #19998E;
            border-radius: 2em;
            padding: 0.3em 0.5em;
        }
    }

    .checkout-summary {
        .form-control {
            border-radius: 0.5em;
        }
        &.sticky-lg-top {
            z-index: 1019;
        }
    }

    .insert-coupon {
        .coupon-input {
            border-color: $black;
        }
        .coupon-btn {
            margin-left: .6em;
        }
    }

    .product-terms {
        .product-terms-list {
            font-size: .9em;

            .list-point {
                display: inline-block;
                margin-right: .7em;
            }
        }
    }

    .total {
        color: $black;
        font-size: 1.8em;
    }

    .contacts,
    .infos {
        font-size: 0.9em;
    }
}

.mobile-summary-bar{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px -14px 20px 0px #e3e5e9;
    padding: 1em;
    .checkout-summary-totals{
        .total{
            color:$font-green;
            font-size:1.2em;
        }
        .open-checkout-summary{
            cursor: pointer;
        }
    }
}

.info-bar{
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 1em;
    padding: 1.5em;
    margin-top: 2em;
    font-size:.9em;
}



body {
    &.mobile-filter-open,
    &.mobile-checkout-summary-open{
        overflow: hidden;

        #header{
            display: none;
        }

        .catalog-container{
            .filters-container {
                z-index: 99;
                display: block !important;
                width: 100%;
                height: 100%;
                padding: 2em 1.5em;
                position: fixed;
                overflow-y: scroll;
                top: 0;
                left: 0;
                color: #fff;
                background-color: $black;

                .filter-title {
                    color: #fff;
                    margin-bottom: 0;
                }

                .filters-close {
                    border-bottom: 1px solid $gray;
                }
            }
        }
    }

    &.mobile-checkout-summary-open{
        .checkout-summary{
            display: block !important;
        }
    }

}


/* --------------------------  Blocchi da creare ---------------------------- */

.page-title {
    font-size: 2em;
}

.dk-block {
    margin-bottom: 5em;
}

.section-padding {
    padding: 2em 0;
}

.dk-block-about {
    position: relative;

    .theme-background {
        @media (max-width: $breakpoint-md-max) {
            background-color: $black;
        }
    }

    .dk-block-about-container {
        padding: 3em 0;

        @media (max-width: $breakpoint-sm-max) {
            padding: 4em 0;
        }

        .dk-block-about-left-col {
            img {
                margin-right: 1.5em;

                @media (max-width: $breakpoint-sm-max) {
                    width: 60px;
                }

                @media (max-width: $breakpoint-xs-max) {
                    margin-right: 1em;
                }
            }

            .description {
                font-size: 2em;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: .6em;

                @media (min-width: $breakpoint-lg-min) {
                    margin: 1em 0;
                }
            }
        }

        .dk-block-about-right-col {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            border-radius: 24px 0 0 24px;
            overflow: hidden;

            .image {
                background-size: cover;
                background-position: left;
                background-repeat: no-repeat;
                background-image: url("/images/app/demo/home_about_us.jpg");
            }
        }

        .about-cta {
            width: 100%;
        }
    }
}

.dk-block-see-more {
    .dk-block-see-more-container {
        border-radius: 24px;
    }

    .theme-black {
        border-radius: 24px;
        background-color: $black;
    }

    .black-square {
        @media (max-width: $breakpoint-md-max) {
            height: 300px;
        }

        @media (max-width: $breakpoint-xs-max) {
            height: 250px;
        }
    }

    .dk-block-see-more-content {
        padding: 3em 4em;

        @media (max-width: $breakpoint-md-max) {
            padding: 2em;
        }

        @media (max-width: $breakpoint-xs-max) {
            padding: 1.5em 1.2em 1.2em;
        }
    }

    .see-more-cta {
        width: 50%;

        @media (max-width: $breakpoint-xs-max) {
            width: 100%;
        }
    }
}

.dk-block-about-agency {
    .content-block {
        text-align: center;
        color: $black;
        border: 1px solid $black;
        border-radius: 48px;
        overflow: hidden;

        .number {
            font-size: 2em;
        }

        .caption {
            font-size: .8em;
        }

        .image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    .people-content,
    .adv-content {
        background: $lime;
    }

    .experiments-content {
        min-height: 300px;

        .image {
            background-image: url("/images/app/demo/experiments_launched.jpg");
        }
    }

    .foundation-content {
        @media (max-width: $breakpoint-sm-max) {
            height: 150px;
        }

        .image {
            background-image: url("/images/app/demo/2018_foundation.jpg");
        }
    }
}

.dk-block-category {
    .dk-block-category-wrapper {
        height: 100%;
        border-radius: 16px;
        background-color: #F6F6F6;
    }
    .dk-block-category-image {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dk-block-category-title {
        margin:1em 0;
        font-weight: 600;
    }
}

.dk-block-features {

    .dk-block-features-title {
        margin: .5em 0;
        font-weight: 600;
    }
}

.dk-block-top-offers,
.dk-block-recommended {
    overflow: hidden;

    .page-title {
        position: relative;

        @media (max-width: $breakpoint-sm-max) {
            display: flex;
            justify-content: space-between;
        }
    }

    .swiper {
        padding-bottom: 10px;
    }

    .swiper-slide {
        height: auto;
    }

    .swiper-buttons-container {
        @media (max-width: $breakpoint-sm-max) {
            position: relative;

            .swiper-button-prev {
                right: 5px;
                left: auto;
            }

            .swiper-button-next {
                left: 5px;
                right: auto;
            }
        }
        @media (max-width: $breakpoint-xs-max) {
            display: none;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: auto;
        height: auto;

        &::after{
            width: 40px;
            height: 40px;
        }
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-next::after, .swiper-rtl .swiper-button-prev::after {
        background-image: url("/images/app/demo/arrow-right.svg");
        background-position: center left;
    }

    .swiper-button-prev::after, .swiper-rtl .swiper-button-next::after {
        background-image: url("/images/app/demo/arrow-right.svg");
        background-position: center right;
        transform: rotate(180deg);
    }

    .swiper-button-disabled {
        display: none;
    }

    .product {
        position: relative;
        display: flex;
        height: 100%;
        font-size: 1em;
        border-radius: 1em;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        transition: .3s;

        a {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .tag-container {
            z-index: 2;
            position: absolute;
            top: 1em;
            left: 1em;

            .tag-content {
                .tag {
                    font-size: .8em;
                    padding: .25em .5em;
                    text-transform: uppercase;
                    line-height: initial;
                    color: $black;
                    border: 1px solid $black;
                    border-radius: .5em;
                    background-color: $white;
                }
            }
        }

        .image-container{
            figure {
                overflow: hidden;
                border-radius: 1em;
            }

            img {
                width: 100%;
                border-radius: 1em;
                transition: .3s;
            }
        }

        .product-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 1em;
        }

        .category-container {
            font-size: .7em;
            text-transform: uppercase;
        }

        .name-container {
            display: flex;
            flex-grow: 1;
        }

        .subcategories-container {
            .subcategories-content {
                font-size: 0.8em;
            }

            .subcategory-pick {
                display: inline-block;
                font-size: .9em;
                border-radius: 6px;
                padding: .2em .6em;
                text-transform: uppercase;
                background-color: $lime;
            }
        }

        hr {
            border: 1px solid $light-gray;
        }

        .price-container {
            font-size: 0.8em;

            .price-content {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .final-price {
                    font-size: 1.4em;
                    margin-right: 5px;
                }

                .original-price {
                    color: $light-gray;
                    text-decoration: line-through;
                }
            }
        }

        &:hover{
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08);
            transition: .3s;

            .image-container {
                overflow: hidden;

                img {
                    transform: scale(1.05);
                    transition: .3s;
                }
            }
        }
    }
}

.dk-block-top-offers {
    .page-title {
        padding-right: 2em;
    }

    .swiper {
        overflow: visible;
    }
}

.dk-block-recommended {
    @media (max-width: $breakpoint-xs-max) {
        .swiper {
            overflow: visible;
        }
    }
}


.app-guest{
    main{
        background-color: #f8f8f8;
        min-height: calc(100vh - 11em);
    }
}

.notifications-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3000;

    .container {
        position: relative;

        .notification-track {
            position: absolute;
            right: 15px;
            top: 100px;
            width: 300px;
            z-index: 3100;
            transition: opacity 0.5s;
            opacity: 1;

            &.hide {
                opacity: 0;
                display: block !important;
            }

            .notification {
                opacity: 0;
                overflow: hidden;
                max-height: 0;
                will-change: opacity;
                transition: opacity 0.3s, max-height 1s;

                .notification-content {
                    padding: 15px;
                    margin: 8px 8px 20px;
                    color: $dark-gray;
                    background-color: $lime;
                    -webkit-box-shadow: 0 0 12px #333;
                    box-shadow: 0 0 12px #333;

                    .notification-header {
                        width: auto;
                        margin: -15px -15px 10px;
                        padding: 15px;
                        color: $black;
                        background-color: #fff;

                        figure {
                            margin-bottom: 10px;

                            img {
                                width: 100%;
                            }
                        }
                    }
                }

                &.active {
                    opacity: 1;
                    max-height: none;
                    transition: opacity 0.5s;
                }
            }
            #notification-template {
                display: none;
            }
        }
    }
}

#spinner {
    &.loader {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 999;
        text-align: center;

        &.hidden {
            display: none;
        }

        .loader-wheel {
            animation: spin 1s infinite linear;
            border: 2px solid $gray;
            border-left: 4px solid $lime;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            margin: 0 auto;
        }

        .loader-text:after {
            content: 'Ricerca';
            animation: load 2s linear infinite;
        }

        .loader-text {
            color: $gray;
            margin: 0 auto;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes load {
            0% {
                content: 'Ricerca';
            }
            33% {
                content: 'Ricerca.';
            }
            67% {
                content: 'Ricerca..';
            }
            100% {
                content: 'Ricerca...';
            }
        }
    }
}

.auth-container {
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 4em);
    padding: 1em 0;
    background-image: linear-gradient(to bottom, $lime, $white);

    .auth-box {
        padding: 1.5em 2em 2em;
        background-color: $white;

        p {
            margin: 0;
        }

        .heading {

            .title {
                display: flex;
                justify-content: space-between;

                p {
                    font-size: .9em;
                    color: $gray;
                }
            }

            .description {
                margin-bottom: 2em;
                font-size: .9em;
            }
        }

        .content {

            .forgot-password {
                display: block;
                width: 100%;
                text-align: right;
                color: inherit;
                font-size: .9em;
            }

            .new-user {
                font-size: .9em;
            }
        }
    }

    // iPad or lower
    @media (max-width: $breakpoint-md-max) {

        .auth-box .heading .title {
            display: block;
            margin-bottom: 1em;
        }
    }
}
/* -------------------------- Profile Page ----------------------------- */



/* purgecss end ignore */
