@import 'elements/search';

/* -------------------------- Forms ------------------------------- */

input,
label,
select,
button,
textarea
{
    margin:0;
    border:0;
    padding:.4em;
    display:inline-block;
    vertical-align:middle;
    white-space:normal;
    background:none;
    line-height:1;

    /* Browsers have different default form fonts */
    font-size:1em;
}

/* Remove the stupid outer glow in Webkit */
input:focus
{
    outline:0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea
{
    -webkit-box-sizing:content-box;
    -moz-box-sizing:content-box;
    box-sizing:content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select
{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}

/* Text Inputs
-----------------------------------------------*/

input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week]
{
}

/* Button Controls
-----------------------------------------------*/

input[type=checkbox],
input[type=radio]
{
    width:1em;
    height:1em;
}

/* File Uploads
-----------------------------------------------*/

input[type=file]
{

}

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type=search]
{
    -webkit-appearance:textfield;
    -webkit-box-sizing:content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration
{
    display:none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type="reset"],
input[type="button"],
input[type="submit"]
{
    /* Fix IE7 display bug */
    overflow:visible;
    width:auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button
{
    padding:0;
    border:0;
    background:none;
}

/* Textarea
-----------------------------------------------*/

textarea
{
    /* Move the label to the top */
    vertical-align:top;

    /* Turn off scroll bars in IE unless needed */
    overflow:auto;
}

/* Selects
-----------------------------------------------*/

select
{

}

select[multiple]
{
    /* Move the label to the top */
    vertical-align:top;
}


/* -------------------------- Form Customization ------------------------------- */

.form-group{
    margin: .6em 0;

    hr {
        height: 0;
        border-bottom: 1px solid #A2AAB8;
    }

    label{
        color: $font-black;
        font-weight: 600;
    }

    input, select{
        background: #FFFFFF;
        border: 1px solid #A2AAB88C;
        box-sizing: border-box;
        border-radius: .2em;
        width: 100%;
        padding: 0.8em .8em;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #A2AAB8;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #A2AAB8;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #A2AAB8;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #A2AAB8;
        }

        &:hover, &:focus{
            border-color: $font-black;
        }
    }

    select{
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='7' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.8533 0.645998C12.9471 0.739629 13 0.866709 13.0001 0.999291C13.0003 1.13187 12.9479 1.2591 12.8543 1.353L7.38926 6.837C7.33814 6.8884 7.27738 6.92919 7.21045 6.95702C7.14352 6.98485 7.07174 6.99918 6.99926 6.99918C6.92677 6.99918 6.855 6.98485 6.78807 6.95702C6.72114 6.92919 6.66037 6.8884 6.60926 6.837L1.14626 1.353C1.09857 1.30681 1.06056 1.25158 1.03445 1.19054C1.00833 1.1295 0.994635 1.06388 0.994154 0.997489C0.993673 0.931101 1.00642 0.865282 1.03164 0.803872C1.05687 0.742462 1.09408 0.686691 1.14109 0.639813C1.1881 0.592934 1.24397 0.555888 1.30546 0.530834C1.36694 0.505781 1.43279 0.493222 1.49918 0.493891C1.56556 0.49456 1.63115 0.508443 1.69212 0.53473C1.75308 0.561017 1.8082 0.599182 1.85426 0.646998L7.00026 5.812L12.1473 0.646998C12.2409 0.55313 12.368 0.500292 12.5005 0.500105C12.6331 0.499917 12.7604 0.552395 12.8543 0.645998H12.8533Z' fill='%23222325'/%3E%3C/svg%3E%0A");
        background-position: right 1em top 50%;
        background-repeat: no-repeat;
    }

    &.has-error {
        input, select {
            border: 2px solid darkred;
        }
    }

    &.form-checkbox{
        label {
            position: relative;
            margin: 0;
            padding-left: 1.8em;
            cursor: pointer;
            font-weight: normal;
            display: block;

            &::before {
                content: '';
                position: absolute;
                top: .2em;
                left: 0;
                width: 1.2em;
                height: 1.2em;
                background-color: #fff;
                border-radius: .2em;
                border: 1px solid #A2AAB88C;
                transition: background-color .1s ease-in-out;
            }


        }

        input[type=checkbox] {
            display: none;

            &:checked + label::before {
                background-color: $font-black;
                box-shadow: inset 0 0 1px 1px #fff;
            }
        }

        &.form-border{
            label{
                border:1px solid #A2AAB88C;
                padding: .8em .8em .8em 2.2em;
                border-radius: .4em;
                line-height: 1.2;

                &::before {
                    top: .9em;
                    left: .5em;
                    width: 1.2em;
                    height: 1.2em;
                }
            }

            &:hover{
                label{
                    border-color: $font-black;
                }

            }

            input[type=checkbox] {

                &:checked + label {
                    border-color: $font-black;
                }
            }

        }
    }

    &.form-radio {
        label {
            display: block;
            position: relative;
            border:1px solid #A2AAB88C;
            cursor: pointer;
            padding: .8em .8em .8em 2.2em;
            font-weight: normal;
            line-height: 1.2;

            &.has-error {
                border:2px solid darkred;
            }

            .status-checked{
                display: none;
            }

            &:hover{
                border-color: $font-black;
            }

            &::before {
                content: '';
                position: absolute;
                top: .9em;
                left: .5em;
                width: 1.2em;
                height: 1.2em;
                background-color: #fff;
                border-radius: .6em;
                border: 1px solid #A2AAB88C;
                transition: background-color .1s ease-in-out;
            }
        }

        input[type=radio] {
            display: none;

            &:checked + label {
                border-color: $font-black;
                .status-price{
                    display:none;
                }
                .status-checked{
                    display: block;
                    svg{
                        width: 1em;
                        height: 1em;
                    }

                }
            }

            &:checked + label::before {
                background-color: $font-black;
                box-shadow: inset 0 0 1px 1px #fff;

            }
        }
    }

    &.input-group{
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
            margin-left: .6em;
            border-radius: 1.5em;
            background-color: #F9F9F9;
            color: $font-grey;
            border: 1px solid #A2AAB88C;
        }
    }

    .form-btn{
        background-color: $lime;
        padding: 1em 2em;
        border-radius: 1.5em;
        position:relative;
        transition: background-color .1s ease-in-out;
        width: auto;
        color: $black;

        &.form-btn-primary{
            background-color: $purple-dark;
        }

        &.form-btn-secondary{
            background-color: $font-black;
        }

        &.form-btn-white {
            background-color: $font-white;
            color: $font-black;

            &:hover {
                color: $font-white;
            }
        }

        &.order-proceed{
            padding: 1em 2em 1em 4em;
            &:before{
                content: '';
                position: absolute;
                top: .7em;
                left: 2em;
                width: 1.4em;
                height: 1.4em;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7992 8.79991C10.7994 9.1723 10.6841 9.53559 10.4692 9.8397C10.2543 10.1438 9.95031 10.3738 9.59922 10.4979V12.9999C9.59922 13.159 9.53601 13.3117 9.42348 13.4242C9.31096 13.5367 9.15835 13.5999 8.99922 13.5999C8.84009 13.5999 8.68748 13.5367 8.57495 13.4242C8.46243 13.3117 8.39922 13.159 8.39922 12.9999V10.4979C8.08813 10.3879 7.81326 10.1945 7.60465 9.93891C7.39603 9.68327 7.26169 9.37521 7.21632 9.04838C7.17095 8.72156 7.21628 8.38855 7.34736 8.08575C7.47845 7.78295 7.69024 7.52201 7.9596 7.33143C8.22896 7.14086 8.54552 7.028 8.87469 7.00517C9.20386 6.98234 9.53297 7.05043 9.82605 7.202C10.1191 7.35356 10.3649 7.58277 10.5366 7.86458C10.7082 8.14639 10.799 8.46995 10.7992 8.79991Z' fill='%23ffffff'/%3E%3Cpath d='M9.33201 0.500759C9.23354 0.43523 9.11789 0.400269 8.99961 0.400269C8.88133 0.400269 8.76568 0.43523 8.66721 0.500759C6.40177 2.02799 3.81935 3.02133 1.11441 3.40596C0.97149 3.42646 0.840756 3.49781 0.746196 3.60692C0.651635 3.71603 0.59959 3.85558 0.599609 3.99996V9.39996C0.599609 14.0692 3.36801 17.476 8.78361 19.5604C8.92261 19.614 9.0766 19.614 9.21561 19.5604C14.6312 17.476 17.3996 14.068 17.3996 9.39996V3.99996C17.3996 3.8554 17.3474 3.7157 17.2526 3.60657C17.1578 3.49743 17.0267 3.4262 16.8836 3.40596C14.1791 3.02115 11.5971 2.02782 9.33201 0.500759ZM1.79961 4.51356C4.17684 4.11093 6.45673 3.2633 8.51961 2.01516L8.99961 1.71636L9.47961 2.01516C11.5425 3.2633 13.8224 4.11093 16.1996 4.51356V9.39996C16.1996 13.4704 13.838 16.4356 8.99961 18.3556C4.16121 16.4356 1.79961 13.4704 1.79961 9.39996V4.51356Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
            }
        }

        &.duplicate-product-in-cart {
            padding: 0.5em 1em;
            background-color: #fff;
            border: 1px solid $font-black;
            color: $font-black;
        }

        &.disabled{
            background-color: #E3E5E9 !important;
            cursor:default;
        }

        &:hover{
            color: $white;
            background-color: $gray;
            &.form-btn-primary{
                background-color: rgba(121, 90, 244, 0.76);
            }
            &.form-btn-secondary{
                background-color: rgba(34, 35, 37, 0.76);
            }
            &.duplicate-product-in-cart {
                border: 1px solid $purple-dark;
                color: white;
            }
        }

        &.btn-block{
            width: 100%;
        }

    }



}



.modal{
    .modal-content{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
        border-radius: 1em;
    }
}
