/******************* General ********************/

$font-black: #222325;
$font-grey: #545A6A;
$font-white: #FFFFFF;
$font-green: #27AE60;

/******************* Palette ********************/

$purple-dark:#795AF4;
$purple-light:#F1EDFF;
$water-dark:#19998E;
$water-light:#EBF6FA;
$brick-dark:#E45D50;
$brick-light:#FFF8EE;
$green-dark:#18DA48;
$green-light:#DCFFE6;
$blue-dark:#0095D0;
$blue-light:#F5F5F7;
$red-dark:#FA2D48;
$red-light:#FFF4F4;
$orange-dark:#D96826;
$orange-light:#FFFBF2;

$lime: #D5F75C;
$black: #1F1F1F;
$dark-gray: #333333;
$gray: #4F4F4F;
$breadcrumb-link: #828282;
$light-gray: #C6C6C6;
$white: #FCFCFC;

.font-black{
    color:$font-black;
}

.font-grey{
    color:$font-grey;
}

.theme-lime{
    .theme-background{ background-color: $lime; }
    .theme-background-dark{ background-color: $black; }
    .theme-color{ color: $black; }
    .theme-color-light{ color: $gray; }
    .theme-color-dark{ color: $white; }
}
.theme-grey{
    .theme-background{ background-color: $gray; }
    .theme-background-dark{ background-color: $lime; }
    .theme-color{ color:$white; }
    .theme-color-dark{ color: $black; }
}
.theme-black{
    .theme-background{ background-color: $black; }
    .theme-background-dark{ background-color: $lime; }
    .theme-color{ color: $white; }
    .theme-color-dark{ color: $black; }
}

//da qui in poi non servono
.theme-purple{
    .theme-background{ background-color: $purple-light; }
    .theme-background-dark{ background-color: $purple-dark; }
    .theme-color{ color:$purple-dark; }
    .theme-color-dark{ color:$purple-light; }
}
.theme-water{
    .theme-background{ background-color: $water-light; }
    .theme-background-dark{ background-color: $water-dark; }
    .theme-color{ color:$water-dark; }
    .theme-color-dark{ color:$water-light; }
}
.theme-brick{
    .theme-background{ background-color: $brick-light; }
    .theme-background-dark{ background-color: $brick-dark; }
    .theme-color{ color:$brick-dark; }
    .theme-color-dark{ color:$brick-light; }
}
.theme-green{
    .theme-background{ background-color: $green-light; }
    .theme-background-dark{ background-color: $green-dark; }
    .theme-color{ color:$green-dark; }
    .theme-color-dark{ color:$green-light; }
}
.theme-blue{
    .theme-background{ background-color: $blue-light; }
    .theme-background-dark{ background-color: $blue-dark; }
    .theme-color{ color:$blue-dark; }
    .theme-color-dark{ color:$blue-light; }
}
.theme-red{
    .theme-background{ background-color: $red-light; }
    .theme-background-dark{ background-color: $red-dark; }
    .theme-color{ color:$red-dark; }
    .theme-color-dark{ color:$red-light; }
}
.theme-orange{
    .theme-background{ background-color: $orange-light; }
    .theme-background-dark{ background-color: $orange-dark; }
    .theme-color{ color:$orange-dark; }
    .theme-color-dark{ color:$orange-light; }
}

/************** Screen Breakpoints **************/

// Min
$breakpoint-xs-min: 0;
$breakpoint-sm-min: 576px;
$breakpoint-md-min: 768px;
$breakpoint-lg-min: 992px;
$breakpoint-xl-min: 1200px;
$breakpoint-hd-min: 1366px;
$breakpoint-fhd-min: 1920px;
$breakpoint-2k-min: 2560px;
$breakpoint-uhd-min: 3840px;
$breakpoint-4k-min: 4096px;
$breakpoint-8k-min: 8192px;

// Max
$breakpoint-xs-max: $breakpoint-sm-min - 1; // 575px
$breakpoint-sm-max: $breakpoint-md-min - 1; // 767px
$breakpoint-md-max: $breakpoint-lg-min - 1; // 991px
$breakpoint-lg-max: $breakpoint-xl-min - 1; // 1199px
$breakpoint-xl-max: $breakpoint-hd-min - 1; // 1365px
$breakpoint-hd-max: $breakpoint-fhd-min - 1; // 1365px
$breakpoint-fhd-max: $breakpoint-2k-min - 1; // 1919px
$breakpoint-2k-max: $breakpoint-uhd-min - 1; // 2559px
$breakpoint-uhd-max: $breakpoint-4k-min - 1; // 3839px
$breakpoint-4k-max: $breakpoint-8k-min - 1; // 4095px

// Easings
$easing-out-quint: cubic-bezier(0.22, 1, 0.36, 1);
