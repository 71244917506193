/* purgecss start ignore */

/* --------------------------  Rows / Cols ---------------------------- */


.dk-row {
    padding: 0;
    margin: 4em 0;

    /*&:first-child {
        margin-top: 0;
    }*/
}

.dk-col, .dk-block {
    p{
        margin-bottom: 1em;
    }

    .page-title{
        font-size: 2em;
    }
}

.dk-block-disabled {
    background-color: rgba(255, 43, 0, .5);

}

.dk-block-content.text-content {
    h3 {
        margin: 1rem 0;
    }

    h5, h6 {
        font-family: 'G Display', sans-serif;
        font-size: inherit;
        font-weight: 400;
        text-transform: uppercase;
    }

    .one-column {
        h5, h6 {
            &:before {
                content: '';
                display: inline-block;
                height: 23px;
                width: 38px;
                margin-right: .3em;
                background-image: url('/images/app/demo/category-3.svg');
                background-size: contain;
                background-repeat: no-repeat;
                vertical-align: middle;
            }
        }
    }

    .image-container {
        background-color: #000;
        @media (min-width: $breakpoint-md-min) {
            border-radius: 20px;
        }
    }

    ul, ol {
        padding: 0;
        font-family: "Inter", sans-serif;
        font-size: 1em;
        line-height: 1.4;
        list-style: none;

        li {
            position: relative;
            padding-left: 2em;
        }
    }

    ul li::before {
        content: "\2022";
        position: absolute;
        left: 0;
        top: -.25em;
        color: $font-black;
        font-size: 1.4em;
        font-weight: bold;
    }

    ol {
        counter-reset: li;

        li {
            counter-increment: li;

            &::before {
                content: counter(li) "."; // number
                position: absolute;
                left: 0;
                top: .15em;
                color: $font-black;
                font-size: .9em;
                font-weight: bold;
            }
        }
    }

    .table-responsive {
        background-color: $font-white;
        -webkit-box-shadow: 0 0 1em 0 rgba($font-black, .15);
        box-shadow: 0 0 1em 0 rgba($font-black, .15);
    }

    table:not(.custom-style) {
        table-layout: fixed;
        overflow-wrap: break-word;
        width: 100%;
        -webkit-box-shadow: 0 0 1em 0 rgba($font-black, .15);
        box-shadow: 0 0 1em 0 rgba($font-black, .15);

        thead {
            tr {
                background-color: $font-black;
                color: $font-white;

                th {
                    padding: 1.25em 1.5em;
                }
            }
        }

        tbody {

            tr {
                &:nth-child(odd) {
                    //background-color: $white;
                }

                &:nth-child(even) {
                    background-color: rgba($font-black, .05);
                }

                &.grey {
                    background-color: rgba($font-black, .05);
                }

                td {
                    padding: .75em 1.5em;
                }
            }
        }

        &.not-striped {
            tbody {
                tr:nth-child(odd), tr:nth-child(even) {
                    background-color: $font-white;
                }
            }
        }
    }

    // iPad or lower
    @media (max-width: $breakpoint-md-max) {

        .table-responsive {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            $shadow-height: 100%;
            $shadow-color: rgba($font-black, .15);
            $shadow-weight: 1.5em;

            background:
                    linear-gradient(90deg, white 0%, rgba($font-white, 0)),
                    linear-gradient(-90deg, white 0%, rgba($font-white, 0)) 100% 0,
                    linear-gradient(90deg, $shadow-color, rgba($font-black, 0)),
                    linear-gradient(-90deg, $shadow-color, rgba($font-black, 0)) 100% 0;
            background-repeat: no-repeat;
            background-color: $font-white;
            background-size: 100px 100%, 100px 100%, $shadow-weight $shadow-height, $shadow-weight $shadow-height;
            background-attachment: local, local, scroll, scroll;

            table {
                table-layout: auto;
            }
        }
    }
}

.dk-block-tabs{
    .dk-block-tabs-head-link{
        color: #222325;
        font-weight: 600;
        border: 1px solid #fff;
        border-radius: 32px;

        &.active{
            color: #222325;
            background-color: #fff;
            border-color: #222325;
        }
        &:hover{
            color: #fff;
            background-color: #222325;
            border-color: #222325;
        }
    }
    a{
        display: block;
        width: 100%;

        .dk-block-tabs-product{
            background-color: #f8f8f9;
            border-radius: 1em;
            padding: 2em;

            figure{
                height: 18em;
                transition: all .15s ease-in-out;

                img{
                    width: auto;
                    height: 100%;
                    max-height: 18em;

                }
            }
            .product-name-container{
                height: 3em;
                line-height: 3em;
                transition: all .15s ease-in-out;

                .product-name{
                    font-size: 2em;
                    font-weight: 600;
                    transition: all .15s ease-in-out;
                }
            }
            .product-description-container{
                height: 3em;
                transition: all .15s ease-in-out;

                .product-description{
                    line-height: 3em;
                }
                .product-cta{
                    display:none;
                }
            }
        }
        &:hover{
            .dk-block-tabs-product{
                figure{
                    height: 17em;
                }
                .product-name-container{
                    height: 3em;
                }
                .product-description-container{
                    height: 4em;

                    .product-description{
                        display:none;
                    }
                    .product-cta{
                        display:block;
                    }
                }
            }
        }
        /* blocco del prodotto più piccolo */
        &.dk-block-tabs-small{
            font-size: inherit;

            @media (min-width: 992px){
                font-size: .885em;

                .dk-block-tabs-product{
                    padding: 1em;

                    figure{
                        height: 9em;

                        img{
                            max-height: 9em;
                        }
                    }
                    .product-name-container{
                        height: 2em;
                        line-height: 2em;

                        .product-name{
                            font-size: 1.5em;
                        }
                    }
                    .product-description-container{
                        height: 2em;

                        .product-description{
                            line-height: 2em;
                        }
                        .product-cta{
                            margin: 0;

                            .form-btn{
                                font-size: 0.875em;
                                padding: 0.7em 1em;
                            }
                        }
                    }
                }
                &:hover{
                    .dk-block-tabs-product{
                        figure{
                            height: 8em;
                        }
                        .product-name-container{
                            height: 2em;
                        }
                        .product-description-container{
                            height: 3em;
                        }
                    }
                }
            }
        }
    }
}

.dk-block-top-offers{

    .tab-content {
        @media (max-width: $breakpoint-sm-max) {
            padding: 0;
        }
    }

    .dk-block-top-offers-head-link{
        img {
            max-width: 100px;
            max-height: 50px;
            width: 100%;
            filter: grayscale(100%);
            opacity: 0.1;
        }

        &.active {
            img{
                filter: grayscale(0);
                opacity: 1;
            }
        }
    }

    //.product {
    //    position: relative;
    //    display: flex;
    //    height: 100%;
    //    font-size: 1em;
    //    border-radius: 1em;
    //    background-color: #fff;
    //    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    //
    //    a {
    //        display: flex;
    //        flex-direction: column;
    //    }
    //
    //    .tag-container {
    //        position: absolute;
    //        top: 1em;
    //        left: 1em;
    //
    //        .tag-content {
    //            .tag {
    //                font-size: .8em;
    //                display: inline-block;
    //                padding: .25em .5em;
    //                text-transform: uppercase;
    //                line-height: initial;
    //                color: $black;
    //                border: 1px solid $black;
    //                border-radius: .5em;
    //                background-color: $white;
    //            }
    //        }
    //    }
    //
    //    .image-container{
    //        border-radius: 1em;
    //        overflow: hidden;
    //
    //        //img {
    //        //    height: 10em;
    //        //
    //        //    @media (max-width: $breakpoint-sm-max) {
    //        //        height: 8em;
    //        //    }
    //        //}
    //    }
    //
    //    .product-content {
    //        display: flex;
    //        flex-direction: column;
    //        flex-grow: 1;
    //        padding: 1em;
    //    }
    //
    //    .category-container {
    //        font-size: .8em;
    //        text-transform: uppercase;
    //    }
    //
    //    .name-container {
    //        display: flex;
    //        flex-grow: 1;
    //    }
    //
    //    .subcategories-container {
    //        .subcategories-content {
    //            font-size: 0.9em;
    //        }
    //
    //        .subcategory-pick {
    //            display: inline-block;
    //            font-size: .9em;
    //            border-radius: .5em;
    //            padding: .2em .6em;
    //            text-transform: uppercase;
    //            background-color: $lime;
    //        }
    //    }
    //
    //    hr {
    //        border: 1px solid $light-gray;
    //        height: 0;
    //    }
    //
    //    .price-container {
    //        font-size: 0.8em;
    //
    //        .price-content {
    //            .final-price {
    //                font-size: 1.4em;
    //                font-weight: 700;
    //            }
    //
    //            .original-price {
    //                text-decoration:line-through;
    //            }
    //        }
    //    }
    //
    //    &:hover{
    //        box-shadow: 0px 0 2px 0px rgb(120 90 244);
    //
    //    }
    //}
}




/* purgecss end ignore */
